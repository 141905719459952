// 進行同步執行
import Vue from 'vue'

export default {
  setLang(state, value) {
    state.lang = value
  },
  setLoginStatus(state, value) {
    state.loginStatus = value
  },
  setUserName(state, value) {
    Vue.ls.set('setUserName', value)
    state.userName = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setPhone(state, value) {
    state.phone = value
  },
  setCreateTime(state, value) {
    state.createTime = value
  },
  setAddress(state, value) {
    state.address = value
  },
  setCpUrl(state, value) {
    state.cpUrl = value
  },
  setAccessToken(state, value) {
    state.accessToken = value
  },
  setCountryCode(state, value) {
    state.countryCode = value
  },
  setRegulator(state, value) {
    state.regulator = value
  },
  setAutoNotifyStatus(state, value) {
    state.autoNotifyStatus = value
  },
  setEmailMasked(state, value) {
    state.emailMasked = value
  },
  setIsSubUser(state, value) {
    state.isSubUser = value
  },
  setSubUserSwitch(state, value) {
    state.subUserSwitch = value
  },
  setPermissionIds(state, value) {
    state.permissionIds = value
  },
  setSubEmail(state, value) {
    state.subEmail = value
  },
  setAuthHeaders(state, value) {
    state.authHeaders = value
  },
  setCheckedAuthStatus(state, value) {
    state.checkedAuthStatus = value
  },
  setRequiredAuths(state, value) {
    state.requiredAuths = value
  },
  setUserId(state, value) {
    state.userId = value
  },
  setEventEmailId(state, value) {
    state.eventEmailId = value
  },
  setEventMobileId(state, value) {
    state.eventMobileId = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      console.log(key)
      if (key == 'userName') Vue.ls.set('setUserName', '')
      if (key != 'CUID' && key != 'lang') state[key] = ''
      if (key == 'authHeaders')
        state[key] = {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        }
    })
  },
  setId3Pass(state, value) {
    state.id3Pass = value
  },
  setRegisterStep(state, value) {
    state.registerStep = value
  },
  setSessionId(state, value) {
    state.sessionId = value.sessionId
    state.sessionInternalId = value.sessionInternalId
  },
}

export default {
  home: {
    NewPortal: 'بوابة جديدة',
    SecureIBPortal: 'بوابة الوسيط الـ (IB) الآمنة',
    DASHBOARD: 'اللوحة الرئيسية',
    MARKETING_TOOLS: 'أدوات التسويق',
    IB_REPORT: 'تقرير الـ IB',
    REBATE_REPORT: 'تقرير العمولة المرتجعة',
    IB_ACCOUNTS: 'حسابات الـ IB',
    PAYMENT_HISTORY: 'سجل التحويلات',
    IB_FAQ: 'تعليمات الـ IB',
    RebateAccountNumber: 'رقم حساب العمولة المرتجعة',
    totalBalance: 'اجمالي الرصيد',
    totalCommission: 'اجمالي العمولة',
    APPLY_FOR_A_REBATE: 'تطبيق',
    availableBalance: 'الرصيد المتوفر',
    WITHDRAW_REBATE: 'السحب',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'تحويل',
    MonthtoDate: 'الشهر ليوم',
    UPDATE: 'التحديث',
    REBATE: 'العمولة المرتجعة',
    TOTAL_VOLUME: 'اجمالي حجم العقد',
    NET_FUNDING: 'صافي التمويل',
    DEPOSITS: 'الايداع',
    WITHDRAW: 'السحب',
    OPEND_ACCOUNT: 'حساب مفتوح',
    PERFORMANCE: 'الاداء',
    OpendAccount: 'حساب مفتوح',
    select_ib: 'الرجاء اختر حساب الوسيط الـ (IB)',
    MOST_TRADED_INSTRUMENTS: 'الادوات الاكثر تداول',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: ' الحسابات الأكثر أداء لهذا الشهر',
    RECENTLY_OPENED_ACCOUNTS: 'الحسابات المفتوحة حديثا',
    ACCOUNT_MANAGER: 'خدمة العملاء',
    Account: 'الحساب  ',
    Name: 'الاسم',
    Volume: 'الحجم',
    Rebate: 'العمولة المرتجعة',
    Balance: 'الرصيد المتوفر',
    ContactClient: 'الاتصال بعميل',
    YouCannotAskForCommission: 'لاتستطيع طلب عمولة',
    applicationFailedPleaseTryAgain: 'فشل التطبيق ، يرجى المحاولة مرة أخرى لاحقا',
    applyRebateSuccessTip:
      'تهانينا ، لقد تلقينا طلب الخصم الخاص بك. | سيتم تحويل {amount} إلى حساب الخصم {account} قريبًا. | بمجرد المعالجة ، ستتمكن من سحب أموالك أو تحويلها.',
    HOVER:
      'يتم احتساب العمولات المرتجعة لليوم السابق ومعالجتها يوميًا من الساعة 09:00 إلى 11:00 بتوقيت شرق أستراليا.',
    blacklist: 'لا يمكن لهذا الحساب الحصول على حسم في الوقت الحالي، يرجى التواصل مع مدير حسابك.',
    ACCOUNTS_PROFIT:
      'إجمالي الربح/الخسارة التاريخية لجميع حسابات التداول مع حساب IB هذا. قد يستغرق التحميل بعض الوقت.',
    PROFIT: 'الربح الحسابات',
    reload: 'إعادة التحميل ',
  },
  liveChat: { header: 'دردشة مباشرة' },
  contactUs: {
    click: 'انقر',
    assist: 'للحصول على المساعدة الآن',
    header: 'اتصل بنا',
    followUs: 'تابعنا',
    tip: 'إن فريق دعم العملاء المحترف متعدد اللغات التابع لـ {platform} مستعد لمساعدتك ومساعدتك في توفير تجربة تداول لا تضاهى.',
    chatNow: 'دعنا نتحدث الآن',
    globalOffice: 'المكتب العالمي',
  },
  ibAccounts: {
    header: 'حسابات الـ IB',
    ibAccount: 'حساب IB',
    subIbs: 'حساب IB فرعي',
    tradinglist: 'قائمة المعاملات',
    openlist: 'افتح القائمة',
    closedlist: 'القائمة مغلقة',
    tradinglistColumn: {
      ticketid: 'رقم الصفقة',
      opentime: 'وقت الإفتتاح',
      type: 'النوع',
      volume: 'الحجم',
      item: 'الأداة',
      openprice: 'سعر الفتح',
      closeprice: 'سعر الإغلاق',
      closetime: 'وقت الغلق',
      commission: 'العمولة',
      profit: 'الربح',
      buy: 'شراء',
      sell: 'باع',
    },
  },
  withdraw: {
    default: {
      selectCC: 'اختر بطاقة الإئتمان',
      selectBA: 'اختر حساب البنك',
      anotherCC: 'ادخل بطاقة الإئتمان يدويا',
      anotherBA: 'اضف حساب البنك',
      remember: 'تذكر حسابي',
      verificationCode:
        'لقد أرسل رمز التحقق إلى {email}. يرجى التواصل عبر البريد الإلكتروني {supportEmail} إذا كنت بحاجة إلى مزيد من المساعدة',
      fetchTradeAccFailed: 'فشل إحضار حساب التداول',
      failed: 'الرجاء معاودة المحاولة في وقت لاحق',
      tokenExpired: 'انتهت صلاحية الرمز',
    },
    alert:
      'نظرًا لقوانين مكافحة غسل الأموال ، يجب أن تكون الطريقة التي تستخدمها للسحب هي نفس الطريقة المستخدمة في الإيداع الخاص بك. إذا كان مبلغ السحب أقل من 100 وحدة من العملة الأساسية لحسابك ، فسيتم تحميلك 20 وحدة مقابل رسوم المناولة من قبل البنك.',
    NetellerChargeAlert: 'تفرض Neteller رسم معاملة {percentageFee}% على كل سحب.',
    FasaPayChargeAlert: 'تفرض FasaPay رسم معاملة 0.5% على كل سحب.',
    SkrillChargeAlert: 'تفرض Skrill رسم معاملة {percentageFee}% على كل سحب.',
    cryptoAlert:
      'يرجى ملاحظة أننا غير قادرين على الإيداع والسحب من خلال BUSDT، يرجى التأكد من أن العنوان والعملة المشفرة يطابقان السلسلة والعملة التي نقبلها، وإلا فقد تخسر المال.',
    REBATE_WITHDRAW_REQUEST: 'طلب سحب العمولة  المرتجعة',
    PleaseSelectDesiredWithdrawMethod: 'الرجاء تحديد طريقة السحب المطلوبة',
    BankName: 'اسم البنك',
    BankAccountsName: 'اسم حسابات البنك',
    FasaPayAccountName: 'اسم حساب FasaPay',
    AustralianBankName: 'اسم البنك الاسترالي',
    BankAccountNumber: 'رقم حساب البنك',
    FasaPayAccountNumber: 'رقم حساب FasaPay',
    BankAccountBeneficiary: 'حساب بنك المستفيد',
    BSB: 'BSB',
    skrillEmail: 'ايميل Skrill',
    SticpayEmail: 'ايميل SticPay',
    NetellerEmail: 'ايميل Neteller',
    PerfectMoneyEmail: 'ايميل Perfect Money',
    BitwalletEmail: 'ايميل Bitwallet',
    BitcoinEmail: 'عنوان محفظة البيتكوين',
    USDTEmail: 'عنوان محفظة Tether',
    BankBranch: 'فرع البنك',
    Province: 'المقاطعة',
    City: 'المدينة',
    BankAddress: 'عنوان البنك',
    bankCity: 'مدينة البنك',
    bankProvince: 'مقاطعة البنك',
    AccountNumberIBAN: 'رقم الحساب / IBAN',
    BankBeneficiaryName: 'اسم بنك المستفيد',
    BankAccountBeneficiaryName: 'اسم صاحب حساب بنك المستفيد',
    AccountHoldersAddress: 'عنوان صاحب الحساب  ',
    SelectWithdrawCard: 'حدد بطاقة السحب الخاصة بك',
    Swift: 'كود التحويل',
    ifscCode: 'كود الـ IFSC',
    SwiftAud: 'كود التحويل (حسابات ليس بالدولار الاسترالي)',
    ABA_SortCodeABA: 'ABA / رمز الفرز',
    ImportantNotes: 'ملاحظات هامة',
    YourPaymentGreaterThanAvailableBalance: 'دفعتك أكبر من الرصيد المتاح',
    CannotUseMoreThanTwoDecimalValues:
      'لا يمكنك استخدام الأعداد والأرقام السالبة التي تحتوي على أكثر من منزلتين عشريتين',
    YourPaymentIsBeingProcessed: 'تم تقديم طلب السحب الخاص بك',
    Itcannotbe0: 'لا يمكن أن يكون 0',
    noCardWarn: 'ليس لديك أي بطاقة متاحة للسحب ، يرجى ربط بطاقتك في تفاصيل السحب أولاً',
    amtLarger: 'لا يمكن أن يكون المبلغ فارغًا ويجب أن يكون أكبر من أو يساوي {minLimit} {currency}',
    blackList:
      'للأسف لا يمكنك حاليًا تقديم طلب سحب. يرجى الاتصال بـ {supportEmail} للحصول على مزيد من المعلومات',
    status: {
      withdrawalSubmitted: 'تم التقديم',
      withdrawalCanceled: 'تم الالغاء',
      withdrawalAuditing: 'تتم المعالجة',
      withdrawalRejected: 'تم الرفض',
      withdrawalAccepted: 'تتم المعالجة',
      withdrawalPayFailed: 'فشلت',
      withdrawalSuccess: 'تمت المعالجة',
      withdrawalFailed: 'فشلت',
      withdrawalPending: 'قيد الانتظار',
      withdrawalPartialFailed: 'فشلت',
      transferSubmitted: 'تتم المعالجة',
      transferSuccess: 'تمت المعالجة',
      transferRejected: 'تم الرفض',
      transferFailed: 'فشلت',
      transferProcessing: 'تتم المعالجة',
      withdrawalReversedFailed: 'فشل مقلوب',
      withdrawalPartialSuccess: 'نجاح جزئي',
    },
    formErrorMsg: {
      bankName: 'اسم البنك مطلوب',
      bsb: 'BSB مطلوب الـ',
      beneName: 'مطلوب اسم بنك المستفيد ',
      accNum: ' مطلوب رقم الحساب البنكي ',
      swift: ' مطلوب كود التحويل ',
      bankAddress: 'مطلوب عنوان البنك ',
      holderAddress: 'مطلوب عنوان صاحب الحساب ',
      amt: 'الكمية مطلوبة',
      firstSixDigits: 'ادخل اول ستة ارقام ',
      lastFourDigits: 'ادخل اخر اربعة ارقام',
      branchName: 'مطلوب اسم فرع البنك',
      email: 'ادخل الايميل',
      accName: 'مطلوب اسم الحساب ',
      province: 'منطقة البنك مطلوبة',
      city: 'مدينة البنك مطلوبة',
      bankCard: 'الرجاء اختيار الكرت',
      emailFormat: 'الرجاء ادخال الايميل الصحيح',
      amt0: 'الكمية لايمكن ان تكون 0',
      ifscCode: 'مطلوب رمز الـ IFSC',
      bitcoin: 'عنوان محفظة بيتكوين مطلوب',
      addressLength: 'يجب أن يكون عنوان محفظة البيتكوين (Bitcoin) بين 26 و 42 رمزاً.',
      usdt: 'مطلوب عنوان محفظة USDT',
      beneficiaryNameReq: 'اسم المستفيد من البنك مطلوب',
    },
    transfer: {
      info1:
        '{platform}  ستتنازل عن الرسوم البنكية في أول معاملة سحب في الشهر. ومعاملات السحب الإضافية المجراة خلال نفس الشهر ستؤدي إلى تحمل رسوم بنكية قدرها 20 وحدة من عملة السحب الخاصة بك (بالنسبة لعملة USC/دولار هونغ كونغ (HKD)/الين الياباني (JPY)، ستكون الرسوم مساوية لـ 20 دولارًا أمريكيًا)',
      info2:
        'يرجى التأكد من تعبئة اسم المستفيد البنكي مثلما هو مذكور في جواز السفر بالضبط (يُمنَع الاختصارات، يجب تعبئة الاسم الكامل وفقًا للحرف الأول من الاسم المبين في الكشف)',
      info3:
        'يُفضل أن يكون الحساب البنكي بتنسيق IBAN، وإذا كنت من خارج منطقة الاتحاد الأوروبي، يرجى تعبئة البيانات مثلما هي مبينة بالضبط في كشف الحساب البنكي',
      label: {
        upload: 'يرجى تحميل نسخة من كشف حسابك المصرفي (الحالي إلى آخر 3 أشهر).',
        upload2:
          'يرجى التأكد من أن تاريخ كشف الحساب أو تاريخ آخر معاملة يقع في الشهر الحالي إلى الثلاثة أشهر الماضية. إذا تجاوز ذلك الإطار الزمني، يرجى إعادة تحميل كشف الحساب.',
      },
      formValidation: { upload: 'يرجى تحميل كشف حساب بنكي' },
      minAmount: 'الحدالأدنىلمبلغالسحبهو 100 {currency}',
    },
    transitNumber: 'رقم العبور',
    institutionNumber: 'رقم المؤسسة',
    beneficiaryName: 'اسم المستفيد',
    LBTchannelsNote:
      'ملاحظة:\n  يجب أن يتطابق اسم الحساب المصرفي/اسم المستفيد مع الاسم الموجود في حسابك\n  المصرفي.',
  },
  security: {
    contactSupport:
      'غير قادر على الوصول إلى طريقة المصادقة؟ يرجى الاتصال ب {contact_email} أو Livechat',
    securityAuthenticator: 'مصادقة الأمان',
    verificationRequired: 'التحقق مطلوب',
    resend: 'إعادة الإرسال',
    authenticatorTooltip: 'سيتم تحديث رمز التحقق من تطبيق Authenticator كل 30 ثانية',
    verifyPreEmailNote: 'سيتم إرسال الرمز إلى {email}',
    verifyPostEmailNote: 'تم إرسال الرمز إلى {email}',
    enableNow: 'قم بتمكين الآن',
    verificationDesc: 'يرجى تمكين تطبيق مصادقة الأمان قبل المتابعة.',
  },
  rebate: {
    status: { processed: 'تمت المعالجة', processing: 'تتم المعالجة', rejected: 'تم الرفض' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'طلب تحويل العمولة المرتجعة',
    transferAmountIsGreaterThanAvailableBalance: 'مبلغ التحويل الخاص بك أكبر من الرصيد المتاح',
    yourRebateTransferHasBeenProcessed: 'تم طلب تحويل العمولة المرتجعة',
    PleaseEnterTransferInformation: 'الرجاء إدخال معلومات نقل دقيقة',
    tradingAccount: 'حساب التداول',
    rebateAccount: 'حساب العمولة المرتجعة',
    account: 'الحساب  ',
    blackList:
      'لقد حُظِر على هذا الحساب إجراء التحويلات بين الحسابات، يرجى التواصل مع {supportEmail}',
  },
  common: {
    liveChat: { desc: 'تحتاج مساعدة ؟ دردشة مباشرة' },
    lang: {
      english: 'الإنجليزية',
      chinese: 'الصينية',
      french: 'الفرنسية',
      thai: 'التايلندية',
      german: 'الالمانية',
      spanish: 'الاسبانية',
      malay: 'الملايو',
      viet: 'الفيتنامية',
      indo: 'الاندونيسية',
      arabic: 'العربية',
    },
    button: {
      iKnow: 'أنا أفهم',
      confirmCamelCase: "'تاكيد",
      confirm: 'تاكيد',
      submit: 'ارسال',
      cancel: 'الغاء',
      sendCode: 'إرسال الرمز',
      resendCode: 'أعد إرسال الرمز',
      ok: 'موافق',
    },
    withdrawChannel: {
      limitTitle: 'عمليات السحب غير مدعومة مؤقتًا',
      limitPassword:
        'نظرًا لتعديل كلمة مرور تسجيل الدخول الخاصة بك، السحوبات غير مدعومة مؤقتًا لمدة {day} أيام لضمان أمان حسابك. يُتوقع رفع هذا القيد في {hour} ساعات.',
      limitUserLogin:
        'نظرًا لتعديل عنوان بريدك الإلكتروني/رقم هاتف تسجيل الدخول الخاص بك، السحوبات غير مدعومة مؤقتًا لمدة {day} أيام. يُتوقع رفع هذا القيد في {hour} ساعات.',
      limitSecurityAuthenticator:
        'بسبب تعديل تطبيق الأمان الخاص بك، لا تدعم عمليات السحب مؤقتًا لمدة {day} أيام لضمان أمان حسابك. من المتوقع رفع هذا القيد في {hour} ساعة.',
      banktransfer: 'تحويل بنكي دولي',
      aus: 'تحويل بنكي - استراليا',
      int: 'تحويل بنكي - دولي',
      chn: 'تحويل بنكي - الصين',
      skrill: 'Skrill   ',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'تحويل Union pay',
      fasapay: 'FasaPay',
      creditcard: 'بطاقة ائتمان',
      bpay: 'Bpay',
      creditdebitcard: 'بطاقة ائتمان / كريديت',
      bankwiretransferinternational: 'التحويل البنكي (دولي)',
      banktransferaustralia: 'التحويل بنكي (أستراليا)',
      banktransferinternational: 'تحويل بنكي دولي',
      banktransferchina: 'تحويل بنكي (دولي)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'وسيط لتحويل وسيط',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'الخدمات المصرفية عبر الإنترنت (تايلاند)',
      internetbankingnigeria: 'الخدمات المصرفية عبر الإنترنت (نيجيريا)',
      internetbankingvietnam: 'الخدمات المصرفية عبر الإنترنت (فيتنام)',
      internetbankingmalaysia: 'الخدمات المصرفية عبر الإنترنت (ماليزيا)',
      internetbankingindonesia: 'الخدمات المصرفية عبر الإنترنت (إندونيسيا)',
      internetbankingkorea: 'الخدمات المصرفية عبر الإنترنت (كوريا)',
      internetbankingindia: 'الخدمات المصرفية عبر الإنترنت (الهند)',
      internetbankingphilippine: 'الخدمات المصرفية عبر الإنترنت (الفلبين)',
      internetbankingsouthafrica: 'الخدمات المصرفية عبر الإنترنت (جنوب إفريقيا)',
      internetbankinguganda: '(اوغانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingrwanda: '(رواندا) الخدمات المصرفية عبر الإنترنت',
      internetbankingzambia: '(زامبيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcongo: '(كونغو) الخدمات المصرفية عبر الإنترنت',
      internetbankingcameroon: '(كاميرون) الخدمات المصرفية عبر الإنترنت',
      internetbankingburundi: '(بوروندي) الخدمات المصرفية عبر الإنترنت',
      internetbankingkenya: '(كينيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingghana: '(غانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingtanzania: '(تانزانيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcanada: '(كندا) الخدمات المصرفية عبر الإنترنت',
      internetbankingbrazil: '(البرازيل) الخدمات المصرفية عبر الإنترنت',
      skrillneteller: 'skrill / Neteller',
      vietnambanktransfer: 'فيتنام التحويل المصرفي',
      nigeriabanktransfer: 'نيجيريا التحويل المصرفي',
      polipayment: 'الدفع بالـ poli',
      bankwiretransferaustralia: 'التحويل البنكي (أستراليا)',
      thailandinstantbankwiretransfer: 'تحويل بنكي مباشر لتايلاند',
      malaysiainstantbankwiretransfer: 'تحويل بنكي مباشر لماليزيا',
      banktransferbpaypolipay: 'تحويل بنكي /BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'عملة رقمية - بتكوين',
      cryptocurrencyusdt: 'عملة رقمية - USDT',
      cryptocurrencyethcps: 'عملة رقمية - ETH',
      cryptocurrencyusdccps: 'عملة رقمية - USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'نيجيريا التحويل المصرفي',
      vietnaminstantbankwiretransfer: 'تحويل بنكي مباشر لفيتنام',
      indonesiainstantbankwiretransfer: 'تحويل بنكي مباشر لاندونيسيا',
      philippinesinstantbankwiretransfer: 'التحويل البنكي الفوري الفلبيني',
      southafricainstantbankwiretransfer: 'التحويل البنكي الفوري لجنوب أفريقيا',
      indiainstantbanktransfer: ' تحويل بنكي مباشر للهند',
      southkoreabanktransfer: 'التحويل البنكي الفوري لبنك كوريا الشمالية',
      ugandabanktransfer: 'تحويل بنك محلي  اوغاندا',
      rwandabanktransfer: 'تحويل بنك محلي رواندا',
      zambiabanktransfer: 'تحويل بنك محلي زامبيا',
      congobanktransfer: 'تحويل بنك محلي الكونغو',
      cameroonbanktransfer: 'تحويل بنك محلي الكاميرون',
      burundibanktransfer: 'تحويل بنك محلي بوروندي',
      kenyabanktransfer: 'تحويل بنك محلي كينيا',
      ghanabanktransfer: 'تحويل بنك محلي غانا',
      tanzaniabanktransfer: 'تحويل بنك محلي تانزانيا',
      brazilbanktransfer: 'التحويل البنكي في البرازيل',
      banktransferbpaypolipayaustralia: 'التحويل المصرفي / BPay / PoliPay (أستراليا)',
      banktransferbpaypolipayinternational: 'التحويل المصرفي / BPay / PoliPay (دوليًا)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'التحويل المصرفي الكندي المحلي',
      cryptobitcoin: 'بيتكوين - كريبتو',
      cryptotetheromni: 'العملة الرقمية Tether(OMNI)',
      cryptotethererc20: 'العملة الرقمية Tether(ERC20)',
      cryptotethertrc20: 'العملة الرقمية Tether(TRC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'تحويل الى حسابات',
      E_WALLET: 'المحفظة الإلكترونية',
      CRYPTOCURRENCY: 'عملة مشفرة',
      LOCAL_TRANSFER: 'النقل المحلي',
      japanbanktransfer: 'التحويل البنكي في اليابان',
      indiabanktransfer: 'التحويل المصرفي الهند',
      internetbankingmexico: '(لمكسيك) الخدمات المصرفية عبر الإنترنت',
      internetbankinguae: '      الخدمات المصرفية عبر الإنترنت (الإمارات العربية المتحدة)',
      ewallet: 'المحفظة الإلكترونية',
      internetbankingtaiwan: '( تايوان) الخدمات المصرفية عبر الإنترنت',
      internetbankingeu: 'الخدمات المصرفية عبر الإنترنت (EU)',
      cryptousdttrc20: 'العملة الرقمية Tether(ERC20)',
      momopay: 'Momopay',
    },
    country: { international: 'دولي', australia: 'استراليا' },
    field: {
      country_region: 'البلد / المنطقة',
      address: 'العنوان ',
      phone: 'رقم الهاتف',
      nat: 'الجنسية',
      country: 'بلد الاقامة',
      save: 'استثمار',
      annIncome: 'الدخل السنوي',
      emailAdd: 'البريد الالكتروني',
      yes: 'نعم',
      no: 'لا',
      enterVerificationCode: 'أدخل رمز التحقق',
      ebuyAccName: 'اسم حساب EBuy',
      ebuyEmailAddress: 'عنوان البريد الإلكتروني EBuy',
      skrillAccName: 'اسم حساب Skrill',
      skrillEmailAddress: 'عنوان البريد الإلكتروني Skrill',
      netellerAccName: 'اسم حساب Neteller',
      netellerEmailAddress: 'عنوان البريد الإلكتروني Neteller',
      imptNotes: 'ملاحظات هامة',
      paymentAccName: '{name} اسم الحساب',
      paymentAccNum: '{name} رقم الحساب',
      documentType: 'نوع الوثيقة',
      accDigit: 'رقم الحساب',
      docID: 'رقم الوثيقة',
      bankBranchCode: 'رمز الفرع',
      accType: 'نوع الحساب',
      accName: 'اسم الحساب  ',
      accNum: 'رقم الحساب',
      bankName: 'اسم البنك',
      swiftCode: 'رقم الـ SWIFT',
      bankAddress: 'عنوان البنك',
      bankHolderAddress: 'عنوان صاحب الحساب',
      bankBeneficiaryName: 'اسم البنك المستفيد',
      bankAccNum: 'رقم الحساب المصرفي',
    },
    keys: {
      SUBMIT: 'ارسال',
      FROM: 'من ',
      UPDATE: 'التحديث',
      TO: 'الى',
      DATE: 'تاريخ الافتتاح',
      pleaseSelect: 'الرجاء الاختيار',
      amount: 'الكمية',
      rebateAccount: 'حساب العمولة المرتجعة',
      comingSoon: 'قريبا',
      tradingHistory: 'سجل التداول',
      noData: 'لايوجد بيانات',
      NAME: 'الاسم',
      EMAIL: 'الايميل',
      ACCTYPE: 'نوع الحساب',
      PLATFORM: 'المنصة',
      BASECURRENCY: 'العملة الاساسية',
      DATERANGE: 'معدل التاريخ',
      STATUS: 'الحالة',
      DEST: 'المسافة',
      ACCNUM: 'رقم الحساب',
      BALANCE: 'الرصيد المتوفر',
      PROFITLOST: 'الربح',
      MARGINLVL: 'مستوى الهامش',
      ACCEQUITY: 'رأس مال الحساب',
      CREDIT: 'الإئتمان',
      NONE: 'بدون',
      ALL: 'الكل',
      actualAmonut: 'الكمية الفعلية.',
      currency: 'العملة',
      method: 'طريقة',
      REGISTRATION: 'تاريخ التسجيل',
      FTD: 'تاريخ أول ايداع',
      CURRENTCAMPAIGN: 'الحملة الحالية',
      blacklisted: 'مدرج في القائمة السوداء',
      FIRST_TRANS_IN: 'أول تحويل في التاريخ',
      createTime: 'وقت التقديم',
      lastUpdateTime: 'وقت المعالجة',
    },
    dateRange: {
      today: 'اليوم',
      yesterday: 'البارحة',
      lastSevenDays: 'اخر 7 ايام',
      lastThirtyDays: 'اخر 30 يوم',
      weekToDate: 'اسبوع ليوم',
      monthToDate: 'الشهر ليوم',
      quarterToDate: 'ربع ليوم',
      previousWeek: 'الاسبوع السابق',
      previousMonth: 'الشهر السابق',
      previousQuarter: 'الربع السابق',
    },
    products: {
      forex: 'سوق العملات',
      commodities: 'السلع',
      indices: 'المؤشرات',
      crypto: 'العملات الرقمية',
    },
    formValidation: {
      dynamicReq: '{dynamic} مطلوب',
      verCodeReq: 'رمز التحقق مطلوب',
      alphanumericReq: 'الرجاء إدخال أبجدي رقمي',
      answerReq: 'الرجاء التحديد',
      sameAsPOA: 'يجب أن يكون الإدخال مماثلًا لإثبات العنوان',
      sameAsID: 'يجب أن يكون الإدخال مماثلًا للهوية',
      notBlank: 'يُترك فارغًا إذا كان لا ينطبق',
      digits12WithoutDash: 'يرجى ادخال 12 رقما, بدون  "-"',
      enterAsPerId: 'أدخل حسب بطاقة الهوية',
      numberReq: 'الرجاء إدخال الرقم',
      addressReq: 'الرجاء إدخال عنوانك',
      suburbReq: 'الرجاء إدخال مدينتك أو ضاحيتك',
      stateReq: 'الولاية أو المقاطعة مطلوب',
      postcodeReq: 'الرجاء إدخال الرمز البريدي الخاص بك',
      countryReq: 'الرجاء تحديد بلد إقامتك',
      AlphanumericOnlyNotSpace: 'أحرف وأرقام فقط. الفراغ غير مسموح به.',
      AlphanumericOnly: 'فقط حروف وأرقام.',
      NumbersSymbol: 'الأرقام والرموز فقط.',
      NumbersOnlyNotSpace: 'الأرقام فقط. الفراغ غير مسموح به.',
      NumbersOnly: 'الأرقام فقط.',
      LettersSymbol: 'حروف ورموز فقط.',
      LettersSymbolNotSpace: 'حروف ورموز فقط. الفراغ غير مسموح به.',
      LettersOnlyNotSpace: 'حروف فقط. الفراغ غير مسموح به.',
      NotSpace: 'الفراغ غير مسموح به.',
    },
  },
  report: {
    title: { rebateReport: 'تقرير العمولة المرتجعة' },
    rebate: {
      column: {
        name: 'الاسم',
        accountNumber: 'رقم الحساب',
        accountType: 'نوع الحساب',
        volume: 'الحجم',
        rebate: 'العمولة المرتجعة',
        totalRebate: 'مجموع العمولة المرتجعة',
        date: 'التاريخ',
        instrument: 'أداة',
      },
      dropdown: { allInstruments: 'جميع الادوات', all: 'الكل', instruments: 'الادوات ' },
      others: {
        rebate: 'العمولة المرتجعة',
        instrumentsTraded: 'ادوات تم تداولها',
        lots: 'اللوتات',
        totalRebate: 'مجموع العمولة المرتجعة',
        rebates: 'العمولة المرتجعة',
      },
    },
  },
  ibReport: {
    header: 'تقرير الـ IB',
    netFunding: 'صافي التمويل',
    deposits: 'الايداع',
    withdraw: 'السحب',
    openedAccs: 'فتح حساب',
    funding: 'تمويل الحساب',
    withdrawals: 'السحوبات',
    appliedAmount: 'المبلغ المطبق',
    depositAmount: 'قيمة الايداع',
    withdrawalAmount: 'كمية السحب',
    timeLimitMessage:
      'الحد الأقصى لنطاق الاستعلام للتنزيل هو 90 يومًا، يرجى تحديث النطاق التاريخي الجديد',
    successInfo: 'تم التنزيل بنجاح',
    download: 'تحميل',
    comfirm: 'تأكيد',
    defaultTimeMessage: 'الحد الأقصى لنطاق الاستعلام للتنزيل هو 90 يومًا',
  },
  lead: { header: 'عملاء محتملون', ibAccount: 'حساب IB', demoAccounts: 'حسابات تجريبية' },
  pendingAccounts: {
    header: 'حسابات في الانتظار',
    errorDoc:
      'قام المتقدمون التاليون بملئ نموذج الطلب عبر الإنترنت ولكنهم لم يقدموا بعد إثبات الهوية (ID) من أجل تفعيل حساباتهم.',
    acceptedDocType: 'تشمل نماذج إثبات الهوية المقبولة ما يلي:',
    primaryDoc: 'الهوية الاساسية',
    passport: 'نسخة من جواز سفرك الدولي ساري المفعول (صفحة التوقيع)',
    id: 'نسخة من بطاقة الهوية الوطنية الصادرة عن حكومتك',
    dl: 'نسخة من رخصة قيادتك سارية المفعول',
    address: 'الهوية الثانية',
    addressList: [
      'نسخة من فاتورة مرافق حديثة (لا يزيد عمرها عن 6 أشهر)',
      'نسخة من كشف حساب بطاقة الائتمان (لا يزيد عن 6 أشهر)',
      'نسخة من كشف حساب مصرفي (لا يزيد عن 6 أشهر)',
    ],
    tip: 'يرجى تشجيع المتقدمين على تقديم نسخة واحدة من وثائق الهوية الأساسية والثانوية المقبولة إلى',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'سجل التحويلات',
    rebateHistory: 'العمولة المرتجعة',
    withdrawHistory: 'السحوبات',
    transferHistory: 'التحويلات',
    cancelWithdraw: 'هل ترغب في إلغاء طلب السحب؟',
    cancelError:
      'تتم معالجة عملية السحب الخاصة بك في الوقت الحالي. لذلك ، لا يمكننا قبول طلب الإلغاء الخاص بك. لمزيد من المعلومات ، يمكنك التواصل معنا باستخدام ميزة الدردشة الحية.',
  },
  fileUploader: {
    supportedFormat: 'أنواع الملفات المدعومة: png ، jpg ، jpeg ، bmp ، pdf ، doc ، docx',
    supportedFormatCustom: 'أنواع الملفات المدعومة: {files}',
    maxSize: 'الحد الأقصى لحجم ملف التحميل: {maxSize} ميغا بايت',
    maxFileNum: 'أقصى رقم للملف: {maxFileNum}',
    selectFile: 'اختيار ملف',
    maxFileSize: 'يجب ألا يتجاوز حجم الملف الذي تم تحميله {maxSize} ميجا بايت',
    sureToDelete: {
      title: 'هل أنت متأكد؟',
      content: 'هل أنت متأكد من أنك تريد حذف الملف الذي تم تحميله؟',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'تتم المعالجة',
      paid: 'تم الدفع ',
      unpaid: 'لم يتم الدفع',
      rejected: 'تم الرفض',
    },
  },
  referralLinks: {
    header: 'روابط الدعوة',
    ibAcc: 'حساب IB',
    campLang: 'اللغة ',
    cpy: 'نسخ',
    alreadyCPY: 'تم النسخ !',
    liveAcc: 'حساب حقيقي',
    demoAcc: 'حساب تجريبي',
    homePage: 'الصفحة الرئيسية',
    Share: 'مشاركة',
    subIbReferral: 'الإحالة الفرعية إلى IB',
  },
  settings: { ibProfile: 'الملف الشخصي للوسيط الـ IB', info: 'معلومات', reg: 'تم التسجيل في' },
  myProfile: {
    changeDetails:
      'إذا كنت ترغب في تحديث تفاصيل ملفك الشخصي الحالي ، فلا تتردد في الاتصال بـ {mailTo}',
  },
  unfundedAcc: {
    header: 'حسابات غير ممولة',
    allActivationAccs: 'الحسابات النشطة',
    neverFundedAccs: 'لم يتم تمويلها مطلقًا',
    prevFundedZero: 'الرصيد صفر',
  },
  multiib: { level: 'المستوى {level}', noib: 'لا يوجد وسطاء (IB) فرعيون ضمن هذا الحساب.' },
  header: { bckToCP: 'عودة إلى بوابة العميل', logout: 'تسجيل الخروج' },
  menu: {
    dashboard: 'اللوحة الرئيسية',
    ibReport: 'تقرير الـ IB',
    rebateReport: 'تقرير العمولة المرتجعة',
    accManagement: 'الحسابات',
    iblevel: 'مستويات الوسطاء الفرعيين',
    ibAccs: 'الحسابات النشطة',
    leads: 'عملاء محتملون',
    pendingAccs: 'حسابات تحت المراجعة',
    unfundedAccs: 'حسابات غير ممولة',
    payHistory: 'سجل التحويلات',
    ibProfile: 'الملف الشخصي للوسيط الـ IB',
    refLinks: 'روابط الدعوة',
    contactUs: 'اتصل بنا',
    switchCP: 'التبديل إلى بوابة العميل',
  },
  footer: {
    riskWarn:
      'تحذير من المخاطر: تداول العقود مقابل الفروقات (CFDs) يحمل درجة عالية من المخاطرة برأس المال الخاص بك ويمكن أن يؤدي إلى خسائر ، يجب أن تتداول فقط بأموال يمكنك تحمل خسارتها. قد لا يكون تداول العقود مقابل الفروقات مناسبًا لجميع المستثمرين ، يرجى التأكد من فهمك الكامل للمخاطر التي تنطوي عليها واتخاذ الإجراءات المناسبة لإدارتها. يرجى قراءة وثيقة الإفصاح عن المخاطر ذات الصلة بعناية.',
  },
  responseMsg: {
    410: 'فشل التحقق من صحة المعامل',
    411: 'كلمة سر خاطئة',
    420: 'المستخدم غير موجود',
    421: 'رفضت عملية إعادة تعيين كلمة المرور',
    431: 'جارٍ الآن تنفيذ عملية حساب الاستقطاعات، يُرجى المحاولة وتقديم طلبك لاحقًا.',
    490: 'الرجاء عدم تقديم معلومات بطاقة مكررة',
    500: 'حدث خطأ.',
    501: 'فشل التطبيق. الرجاء معاودة المحاولة في وقت لاحق.',
    505: 'فشلت عملية تحميل الملف',
    520: 'فشل تسجيل الدخول. حاول مرة اخرى.',
    521: 'تم تسجيل دخول المستخدم بالفعل على هذا الجهاز!',
    522: 'الرجاد الدخول على الحساب من جديد',
    523: 'الرجاد الدخول على الحساب من جديد',
    524: 'للأسف لم يتم التعرف على عنوان بريدك الإلكتروني',
    525: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    527: 'المستخدم ليس وسيط تقديم',
    528: 'المستخدم غير موجود',
    529: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    530: 'جلسة تسجيل الدخول غير صالحة',
    540: 'تعذر العثور على حساب التداول الخاص بك',
    541: 'لا يمكن العثور على حساب الخصم',
    542: 'لا يسمح لك بفتح حساب إضافي',
    544: 'لا يُسمح لك بتقديم طلب للحصول على حساب إضافي حتى يتم التحقق من إثبات هويتك',
    550: 'رقم الحساب غير صحيح',
    551: 'رصيدك غير كاف',
    553: 'لا يمكنك السحب / التحويل بسبب الرصيد في حسابك. يرجى الاتصال بـ {email} للحصول على مزيد من المساعدة',
    554: 'المبلغ المطلوب صفر أو فارغ',
    562: 'لم نتمكن من معالجة دفعتك ، يرجى المحاولة مرة أخرى. إذا استمرت المشكلة ، يرجى الاتصال بنا عبر الدردشة المباشرة أو البريد الإلكتروني {email}',
    564: 'البلد لا تتطابق مع قناة الدفع',
    565: 'رمز البنك غير صحيح',
    566: 'طريقة الدفع غير موجودة',
    581: 'لقد نجحت في الاشتراك في الترقية. يرجى ملاحظة أنه يجب عليك عمل إيداع لسحب الأموال.',
    582: 'نظرًا لأنك دخلت الحملة ولديك صفقات مفتوحة ، فلن نتمكن من معالجة طلب السحب الخاص بك.',
    584: 'ليس لديك أي خصومات قابلة للتطبيق',
    590: 'لا يمكن العثور على حساب التحويل',
    591: 'لا يمكن العثور على حساب التحويل',
    593: 'لا يمكننا معالجة تحويل أموالك من الحساب المحدد لأن  حسابك يحتوي على رصيد.',
    594: 'لا يسمح بتحويل الأموال من هذا الحساب',
    595: 'نظرًا لأنك دخلت حملتنا ، فلن نتمكن من معالجة طلب التحويل الخاص بك',
    647: 'رمز التحقق غير صحيح',
    1101: 'يوجد 10 حسابات بالفعل، لا يمكن إضافة المزيد.',
    1102: 'غير قادر على التمكين، تم إنشاء الحساب كحساب حقيقي أو تجريبي.',
    1103: 'تم تعطيل الحساب',
    1202: 'انتهت صلاحية التحقق، يرجى المحاولة مرة أخرى',
    1204: 'يرجى المحاولة باستخدام عنوان بريد إلكتروني آخر',
    1208: 'لا يمكن إرسال رموز التحقق بشكل متكرر',
    1209: 'خطأ في إرسال رمز التحقق',
    session_timeout: 'انتهت مدة الجلسة ، إعادة التوجيه إلى صفحة تسجيل الدخول.',
  },
  verificationStatus: {
    title: { default: 'أوشكت على الانتهاء', LV3: 'مرحبًا' },
    dueToAML:
      'أنت مستعد لبدء التداول قريبًا! ساعدنا في حماية حسابك من خلال التحقق من معلوماتك الشخصية.',
    accOpeningVerification: {
      title: 'إنشاء حساب نشط',
      content: 'أنشئ حسابًا نشطًا لبدء تمويل حسابك.',
    },
    identityVerification: {
      title: 'التحقق من الهوية',
      content: 'أرسل إثبات هويتك وتحقق منه لبدء التداول.',
    },
    poaVerification: {
      title: 'التحقق من العنوان',
      content: 'يجب التحقق من إثبات العنوان قبل أن تتمكن من إجراء عملية سحب.',
    },
    kyc: {
      depositNow: 'أودِع الآن',
      withdrawNow: 'السحب الآن',
      createNow: 'إنشاء الآن',
      start: 'بدء',
      complete: 'مكتمل',
      pending: 'معلق',
      rejected: 'مرفوض',
      resubmit: 'إعادة الإرسال',
    },
    resubmitNote: 'قد تكون مستنداتك ناقصة أو غير صحيحة. يرجى إعادة الإرسال.',
    rejectedNote: '.{liveChat} هل لديك أي أسئلة؟ يرجى التواصل معنا عبر',
    liveChat: 'الدردشة المباشرة',
    uploadTemplate: {
      idGuideTitle: 'إرشادات مستند الهوية',
      uploadGuideTitle: 'تحميل الإرشادات',
      desc1: 'صادرة عن الحكومة',
      desc2: 'المستندات الأصلية كاملة الحجم وغير المحررة',
      desc3: 'ضع المستندات أمام خلفية ذات لون واحد',
      desc4: 'صورة ملونة وجيدة الإضاءة ويمكن قراءتها',
      desc5: 'عدم توفير صور بالأبيض والأسود',
      desc6: 'عدم توفير مستندات محررة أو منتهية الصلاحية',
      bottomDesc:
        'يمكن أن يكون الملف الذي يتم تحميله بصيغة jpg أو png أو pdf، ويجب ألا يتجاوز حجم الملف 5 ميجابايت',
    },
  },
  register: {
    progressStatus: {
      title: 'تقدم عملية التحقق',
      estimatedTimeTitle: 'الوقت المقدر',
      min: 'دقائق',
    },
    tabs: {
      personalDetails: 'تفاصيل شخصية',
      moreAboutYou: 'تقاصيل اكثر عنك',
      accountConfig: 'اعدادات الحساب',
      confirmYourId: 'تأكيد هويتك ',
      fundYourAcc: 'تمويل حسابك',
      personalInformation: 'المعلومات الشخصية',
      createLiveAccount: 'إنشاء حساب نشط',
      financialInfo: 'معلومات مالية',
      iDProof: 'إثبات الهوية',
      addressProof: 'إثبات العنوان',
    },
    btn: {
      next: 'التالي',
      back: 'رجوع',
      fundnow: 'مول حسابك الان',
      upload: 'تحميل',
      done: 'تم ',
      bTCP: 'الرجوع إلى بوابة العملاء',
      proceedToUpload: 'تابع للتحميل',
      completeID: 'أكمل عملية التحقق من الهوية',
      completePOA: 'أكمل عملية التحقق من إثبات العنوان',
      depositViaOther: 'إيداع عبر قناة أخرى!',
      withdrawViaOther: 'السحب عبر قناة أخرى',
    },
    authority: {
      dialog1: 'يجب أن يكون لديك حساب حقيقي للوصول إلى هذا القسم من بوابة العميل.',
      dialog2:
        'افتح حسابًا حقيقيًا الآن لفتح الوصول الكامل إلى بوابة العميل الخاصة بك ، والتي تتضمن مجموعة من أدوات التداول والعروض الترويجية وغير ذلك الكثير.',
      openLiveAcc: 'إفتح حساب حقيقي',
    },
    demo: {
      congrad: 'تهانينا !',
      title: 'حسابك التجريبي لمدة 60 يومًا {platform} نشط الآن',
      title2: 'تفاصيل حسابك التجريبي',
      li: { login: 'تسجيل الدخول', srv: 'الخادم', expDate: 'تاريخ الانتهاء' },
      emailSent: 'تم أيضًا إرسال تفاصيل تسجيل الدخول إلى بريدك الإلكتروني المسجل.',
      dl: 'قم بتنزيل منصة التداول MT4 {platform} الخاصة بك',
      improveQ: 'هل ترغب في تحسين التداول الخاص بك',
      improveWay:
        'ببساطة ، افتح حسابًا حقيقيًا في دقائق معدودة لفتح مجموعة من أدوات التداول والعروض الترويجية الحصرية.',
      proTrade: 'تقارير تسويق احترافية',
      customerService: 'خدمة عملاء على مدار الساعة طوال أيام الأسبوع',
      competitivePromotionals: 'عروض ترويجية تنافسية',
      secure: 'تحويلات آمنة بتشفير 3D',
      openLiveAcc: 'إفتح حساب حقيقي',
    },
    personalDetails: {
      page1: {
        pageTitle: 'تفاصيل شخصية',
        userTitle: 'العنوان  ',
        firstName: 'الاسم الاول',
        lastName: 'الاسم الاخير',
        email: 'الايميل',
        dob: 'تاريخ الميلاد',
        idType: 'نوع الهوية',
        referral: 'هل تمت إحالتك من قبل أي شخص？ (اختياري)',
        middleName: 'الاسم الأوسط',
        nationality: 'الجنسية',
        phoneNum: 'رقم الهاتف',
        date: 'اليوم ',
        month: 'الشهر',
        year: 'السنة',
        placeOfBirth: 'تاريخ الميلاد',
        idNum: 'رقم التعريف',
        passportNum: 'رقم جواز السفر',
        driverLicenseNum: 'رقم رخصية القيادة',
        titleDropdown: {
          mr: 'السيد',
          mrs: 'السيدة',
          ms: 'الانسة',
          miss: 'السيدة',
          dr: 'دكتور/ة',
          prof: 'بروفسور/ة',
        },
        idTypeDropdown: {
          passport: 'جوا السفر',
          idCard: 'بطاقة الهوية الوطنية',
          driverLicense: 'رخصة القيادة',
        },
      },
      page2: {
        pageTitle: 'العنوان السكني الرئيسي',
        mainResidency: 'بلد الإقامة الرئيسي',
        streetNumber: 'رقم الشارع',
        streetName: 'اسم الشارع',
        address: 'العنوان',
        provinceOrState: 'المقاطعة / الولاية',
        cityOrSuburb: 'مدينة / ضاحية',
        postcode: 'الرمز البريدي',
        usQuestion:
          'هل أنت مواطن أمريكي / مقيم لأغراض ضريبية أو هل لديك أي روابط / روابط أمريكية (مثل حساب مصرفي ، وما إلى ذلك)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'التوظيف والتفاصيل المالية',
      pageTitle2: 'المعرفة والخبرة في التداول',
      complianceExplain:
        'يتم جمع الأسئلة التالية للامتثال لمتطلبات مكافحة غسل الأموال وتمويل الإرهاب.',
    },
    accountConfig: {
      pageTitle: 'اعدادات الحساب',
      chooseTradingPlatform: 'اختر منصة التداول',
      chooseAccType: 'اختر نوع الحساب',
      chooseAccCurrency: 'اختر عملة الحساب',
    },
    confirmYourId: {
      pageTitle: 'تأكيد هويتك ',
      description:
        'بصفتنا وسيط فوركس منظم ، فنحن مطالبون بالتحقق من هويتك. يرجى تحميل أحد المستندات التالية لإثبات الهوية وإثبات إثبات العنوان. بدلاً من ذلك ، يمكنك إرسال مستندات التحقق بالبريد الإلكتروني إلى {mailTo}',
      poidTitle: 'إثبات الهوية',
      porTitle: 'إثبات الإقامة',
      uploadDescription: 'اختر نوع المستند وقم بتحميله وفقًا لذلك',
      note: 'هام: يجب أن يُظهر الاسم الموجود في المستند اسمك بالكامل بوضوح.',
      pending: { upload: 'في انتظار التحميل', verification: 'في انتظار التحقق' },
      poidTypes: { photoId: 'صورة الهوية', passport: 'جوا السفر', drivingLicence: 'رخصة قيادة' },
      porTypes:
        '<li> فاتورة رسمية </ li>\n      <li> كشف حساب بنكي </ li>\n      <li> شهادة الإقامة </ li>',
    },
    finishPage: {
      title: '{individualUserName} ، شكرًا لك على التقدم للحصول على حساب حقيقي على {platformName}!',
      contentDefault:
        'لقد تلقينا طلبك وقمنا بإعداد حسابك الحقيقي <b> {account} </b>. <br />\n      ستتلقى قريبًا رسالة بريد إلكتروني على <b> {email} </b> تحتوي على تفاصيل تسجيل الدخول الخاصة بك ورابطًا لعرض حالة حسابك. <br />',
      contentWithoutId3WithAccount:
        'تتم حاليًا معالجة حساب التداول الحي الخاص بك وسوف تتلقى رسالة بريد إلكتروني تحتوي على مزيد من المعلومات قريبًا. <br />\n      لتتبع معالجة حسابك المباشر بسرعة ، يمكنك تمويل حسابك الآن والحصول على أولوية الفتح. <br />',
      contentWithoutId3WithoutAccount:
        'تتم حاليًا معالجة حساب التداول الحي الخاص بك وسوف تتلقى رسالة بريد إلكتروني تحتوي على مزيد من المعلومات قريبًا. <br />',
    },
    accOpeningVerification: {
      accOpenVer: 'التحقق من فتح الحساب',
      personalInformation: {
        narrative: 'معلوماتك الشخصية مطلوبة لفتح حسابك النشط',
        firstName: 'الاسم الأول',
        middleName: 'الاسم الأوسط',
        lastName: 'الاسم الأخير',
        gender: 'النوع',
        genderList: { male: 'غير ذلك', female: 'أنثى', other: 'ذكر' },
        dob: 'تاريخ الميلاد',
        day: 'سنة',
        month: 'شهر',
        year: 'يوم',
        mobile: 'رقم الهاتف المحمول',
        countryName: 'بلد الإقامة',
        nationality: 'الجنسية',
      },
      createLiveAccount: {
        narrative:
          'أكمل بضع خطوات قليلة لفتح حساب وبدء التمويل. يوجد مزيد من المعلومات عن أنواع حساباتنا <a href="{link}" target="_blank" alt>هنا</a>.',
        choose: 'اختر {dynamic}',
        tradingPlatform: 'منصة التداول',
        accountType: 'نوع الحساب',
        accountCurrency: 'عملة الحساب',
        islamicTooltip:
          'قد يطلب منك {platform} تقديم مستند (مستندات) إثبات للتحقق من أنك مُسلم. يحتفظ {platform} بالحق في تعديل تكوين حسابك إذا تعذر التحقق.',
        successInfo: {
          title: 'تم إنشاء حساب التداول',
          content:
            'تهانينا! لقد أتممت فتح حساب بنجاح.  <br /> لبدء التداول، قم بالإيداع الآن واستكمل عملية التحقق من هويتك.',
        },
        successInfo2: {
          title: 'تم إنشاء حساب التداول',
          content: 'املأ معلومات ملفك المالي لبدء الخطوة التالية في عملية التحقق من حسابك',
          start: 'بدء',
        },
        generalInfo: {
          title: 'طلبك قيد المراجعة.',
          content:
            'شكرًا لك على فتح حساب في {platform}. يمكنك البدء في تمويل حسابك بمجرد الموافقة على طلبك.',
        },
        rejectedInfo: {
          title: 'مرفوض',
          content: 'يؤسفنا إبلاغك أنه قد تم رفض طلبك لفتح حساب في {platform} .',
        },
      },
    },
    identityVerification: {
      title: 'التحقق من الهوية',
      subTitle: 'معلومات الهوية',
      narrative: 'أدخل التفاصيل',
      firstName: 'الاسم الأول',
      middleName: 'الاسم الأوسط',
      lastName: 'الاسم الأخير',
      nationality: 'الجنسية',
      idType: 'نوع الهوية',
      idSerialNumber: 'الرقم التسلسلي للهوية',
      passportNum: 'رقم جواز السفر',
      driverLicenseNum: 'رقم رخصية القيادة',
      upload: {
        title: 'تحميل مستند',
        desc: 'احرص على أن يكون المستند موضحًا صورتك واسمك بالكامل وتاريخ ميلادك وتاريخ الإصدار.',
        guideline: 'تحميل الإرشادات',
        positive: 'تحميل إيجابي',
        signaturePage: 'تحميل صفحة التوقيع',
      },
      idTypeDropdown: {
        passport: 'جوا السفر',
        idCard: 'بطاقة الهوية الوطنية',
        driverLicense: 'رخصة القيادة',
        other: 'ذكر',
      },
      additionalInfo: { title: 'معلومات إضافية مطلوبة', content: 'تحقق من هويتك لبدء التداول' },
      successInfo: {
        title: 'إكمال عملية التحقق من الهوية',
        content:
          'تهانينا! تم التحقق من هويتك. يمكنك الآن بدء التداول على MT4/MT5 أو على تطبيق {platform} إذا كنت قد قمت بالإيداع بالفعل.',
      },
      generalInfo: {
        title: 'طلبك قيد المراجعة.',
        content:
          'شكرًا لك على فتح حساب في {platform}. يمكنك بدء التداول بمجرد التحقق من معلومات هويتك.',
      },
      rejectedInfo: {
        title: 'مرفوض',
        content: 'يؤسفنا إبلاغك أنه قد تم رفض طلبك لفتح حساب في {platform}.',
      },
      identifyingRequiredInfo: {
        title: 'معلومات إضافية مطلوبة',
        content:
          'قد تكون بعض مستندات التعريف الخاصة بك ناقصة أو غير صحيحة. يرجى إعادة تحميلها للمتابعة.',
      },
      streetNumber: 'رقم الشارع',
      streetName: 'اسم الشارع',
    },
    poaVerification: {
      proofOfAddressVer: 'التحقق من إثبات العنوان',
      subTitle: 'معلومات العنوان',
      narrative: 'أدخل التفاصيل',
      dueToAML:
        'نظرًا إلى شروط مكافحة غسيل الأموال، فإنه يجب التحقق من عنوانك قبل أن تتمكن من إجراء عملية سحب.',
      nationality: 'الجنسية',
      country: 'بلد الإقامة',
      address: 'العنوان',
      postalCode: 'الرمز البريدي',
      citySuburb: 'المدينة/الضاحية',
      city: 'المدينة',
      provinceState: 'المقاطعة/الولاية',
      upload: {
        title: 'شهادة إثبات العنوان',
        inst1: 'فاتورة مرافق (كهرباء، مياه، غاز)',
        inst2: 'كشوف حساب بنكي أو كشوف حساب بطاقة ائتمان',
        desc: 'يجب أن تكون معلومات إثبات العنوان الخاصة بعمليات السحب صادرة خلال الأشهر الستة الماضية',
        guideline: 'تحميل الإرشادات',
        uploadFilesPhoto: 'تحميل ملفات/صور',
      },
      additionalInfo: {
        title: 'معلومات إضافية مطلوبة',
        content: 'يجب التحقق من إثبات العنوان قبل أن تتمكن من إجراء عملية سحب.',
      },
      successInfo: {
        title: 'تم التحقق من إثبات العنوان',
        content: 'تهانينا! لقد تم التحقق من إثبات عنوانك. يمكنك الآن إجراء عمليات سحب.',
      },
      generalInfo: {
        title: 'طلبك قيد المراجعة.',
        content:
          'شكرًا لك على التداول مع {platform}. يمكنك بدء عملية السحب بمجرد الموافقة على إرسالك.',
      },
      identifyingRequiredInfo: {
        title: 'معلومات إضافية مطلوبة',
        content:
          'قد تكون بعض مستندات التعريف الخاصة بك ناقصة أو غير صحيحة. يرجى إعادة تحميلها للمتابعة.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'معلوماتك المالية',
        subTitle: 'البيانات الوظيفة والمالية',
        subTitle2: 'المعرفة والخبرة في التداول',
        employmentStatus: 'الحالة الوظيفية',
        USDRemoteTitle: '(بالدولار الأمريكي) {title}',
        perWeekTitle: 'في الأسبوع {title}',
        estimatedAnnualIncome: 'Eالدخل السنوي التقديري ',
        estimatedSavingsAndInvestments: 'المدخرات والاستثمارات التقديرية',
        estimatedIntendedDeposit: 'الإيداع المخصص التقديري',
        sourceOfFunds: 'مصدر الأموال',
        numberOfTrades: 'عدد التداولات',
        tradingAmount: 'مبلغ التداول',
      },
      iDProof: {
        ver: 'تحميل إثبات الهوية',
        subTitle: 'معلومات الهوية',
        narrative: 'أدخل التفاصيل',
      },
      addressProof: {
        ver: 'تحميل إثبات العنوان (POA)',
        subTitle: 'معلومات العنوان',
        narrative: 'أدخل التفاصيل',
        upload: {
          title: 'المستندات المقبولة لإثبات العنوان',
          inst1: 'فاتورة مرافق (كهرباء، مياه، غاز)',
          inst2: 'كشوف حساب بنكي أو كشوف حساب بطاقة ائتمان',
          desc: 'يجب أن تكون معلومات إثبات العنوان الخاصة بعمليات السحب صادرة خلال الأشهر الستة الماضية',
          guideline: 'تحميل الإرشادات',
          uploadFilesPhoto: 'تحميل ملفات/صور',
        },
      },
      successInfo: {
        title: 'اكتمال مصادقة البنك',
        content: 'تهانينا! لقد تم التحقق بنجاح من بياناتك المصرفية.',
      },
      generalInfo: {
        title: 'طلبك قيد المراجعة.',
        content:
          ' مستنداتك للتحويل المصرفي لا تزال قيد المراجعة. يمكنك إجراء إيداع عبر قناة أخرى إذا كنت ترغب في البدء في التداول الآن.',
      },
      rejectedInfo: {
        title: 'مرفوض',
        content: 'تم رفض طلبك لإجراء عملية إيداع من خلال تحويل بنكي.',
      },
      identifyingRequiredInfo: {
        title: 'معلومات إضافية مطلوبة',
        content:
          'قد تكون بعض مستندات التعريف الخاصة بك ناقصة أو غير صحيحة. يرجى إعادة تحميلها للمتابعة.',
      },
    },
  },
  ibProfile: {
    title: 'الملف الشخصي للوسيط الـ IB',
    subTitle: 'الـ IB المشترك',
    sharedIbList: {
      sharedIbAccount: 'حساب IB مشترك',
      maxSharedIbAccount: 'الحد الأقصى 10 حسابات IB مشتركة',
      add: 'إضافة',
      accountHistory: 'تاريخ حساب IB المشترك',
      setting: 'الإعدادات',
      delete: 'حذف',
      enable: 'تمكين',
      disable: 'تعطيل',
      enabled: 'تمكين',
      disabled: 'تعطيل',
      sharedAccountEnabled: 'تم تمكين حساب IB المشترك.',
      sharedAccountDisabled: 'تم تعطيل حساب IB المشترك.',
      sharedAccountCopied: 'تم نسخ حساب IB المشترك',
      deleteTips: 'لن يتمكن حساب IB المشترك من تسجيل الدخول بعد الحذف، هل تريد حذفه؟',
      sharedAccountDeleted: 'تم حذف حساب IB المشترك.',
      copyTips: 'تم نسخ حساب IB المشترك',
      remark: 'ملاحظة',
      cancel: 'إلغاء',
      accountData: 'بيانات الحساب',
      IBData: 'بيانات تقرير الـ IB',
      fundOperations: 'أذونات الأموال',
      resetPassword: 'إعادة تعيين كلمة المرور',
      accountHistoryBtn: 'تاريخ الحساب المشترك',
    },
    updateSharedIb: {
      addNewSharedIb: 'إضافة حساب IB مشترك جديد',
      editSharedIb: 'تحرير حساب IB مشترك',
      maxSharedIbAccountError: 'يوجد 10 حسابات بالفعل، لا يمكن إضافة المزيد.',
      email: 'البريد الإلكتروني',
      emailValidate: 'الرجاء إدخال عنوان بريد إلكتروني صحيح',
      emailError: 'البريد الإلكتروني موجود، يرجى المحاولة ببريد آخر',
      password: 'كلمة المرور',
      passwordValidate: 'الرجاء إدخال كلمة المرور',
      passwordValidateNum: '8-16 حرفًا',
      passwordValidateTips:
        'مزيج من الحروف الكبيرة والصغيرة (A-Z، a-z)، الأرقام (0-9) والرموز الخاصة (على سبيل المثال: !@#S%^&)',
      passwordValidateWarning:
        '8-16 حرفًا,مزيج من الحروف الكبيرة والصغيرة (A-Z، a-z)، الأرقام (0-9) والرموز الخاصة (على سبيل المثال: !@#S%^&)',
      sharedIbAccountPermission: 'إذن حساب IB المشترك',
      remark: 'ملاحظة',
      remarkTips: 'يمكنك تسجيل هدف الحساب أو حامل الحساب',
      status: 'الحالة',
      cancel: 'إلغاء',
      confirm: 'تأكيد',
      disabled: 'تعطيل',
      enabled: 'تمكين',
      resetPassword: 'إعادة تعيين كلمة المرور',
      emailPlaceholder: 'الرجاء إدخال البريد الإلكتروني',
      addSuccess: 'تم إنشاء حساب مشترك',
      permissionIdsTips: 'يمكنك السماح بالأذونات التالية للحسابات المشتركة أو عدم السماح بها:',
      permissionIdsFirst:
        '<b>بيانات الحساب</b>: عرض صفحات بيانات الحساب (حسابات الـ IB، حسابات تم فتحها مؤخرًا). سيتم إخفاء البيانات إذا كانت الأذن غير مسموحة.',
      permissionIdsSecond:
        '<b>بيانات تقرير الـ IB</b>: عرض صفحات بيانات الـIB (لوحة التحكم، الـIB متعدد المستويات - الحساب). سيتم إخفاء البيانات إذا كان الإذن غير مسموح به.',
      permissionIdsThird:
        '<b>أذونات الأموال</b>: تشغيل أموال IB (التقدم بطلب للحصول على الخصم، خصم التحويل، سحب الخصم)، عرض الخصم (تقرير الخصم، الرصيد، وسجلات الأموال). لا يمكن تنفيذ العمليات إذا كان الإذن غير مسموح به.',
      newPassword: 'الرجاء إدخال كلمة المرور الجديدة',
      newPassowrdReset: 'الرجاء إدخال كلمة المرور الجديدة',
      passwordSame: 'لا يمكن أن تكون كلمة المرور الجديدة هي نفس كلمة المرور القديمة.',
      resetPasswordSuccess: 'تم بنجاح إعادة تعيين كلمة المرور',
      enterNewPassword: 'الرجاء إدخال كلمة المرور الجديدة',
      changePasswordTips:
        'ستصبح الكلمة السرية القديمة غير صالحة وستتم إعادة تعيينها إلى كلمة المرور الجديدة',
      fundsChangeTips:
        'بعد السماح بإذن التمويل، يمكن للحساب المشترك للوسيط القيام بتحويل وسحب رصيد حساب الوسيط الرئيسي الخاص بك. هل ترغب في السماح بذلك؟',
      editSuccess: 'تم التعديل بنجاح',
    },
    sharedIbHistory: {
      allHistory: 'تم عرض كل السجل',
      lastUpdate: 'آخر تحديث',
      refreshTips: 'تم تحديث السجل',
      refresh: 'تحديث',
      chooseAccount: 'اختيار الحساب',
      device: 'الجهاز',
      login: 'تسجيل الدخول',
      commission: 'تطبيق الخصم',
      withdraw: 'سحب الخصم',
      transfer: 'نقل الخصم',
      fromTo: 'من {fromAccount} إلى {toAccount}، المبلغ {total}',
      viewMore: 'عرض المزيد',
    },
    npPermissionTips: 'لا يوجد إذن لهذا الحساب',
  },
  agreement: {
    title: 'تأكيد اتفاقية الوسيط المعرّف (IB)',
    rebateAccount: 'حساب العوائد',
    description:
      'من خلال الضغط على "موافق"، فإنك تؤكد أنك قرأت وفهمت ووافقت على جميع المعلومات وشروط وأحكام الخدمة الواردة في الاتفاقية',
    agree: 'موافق',
    signed: 'حساب الخصم {accountID} . تم التوقيع عليه بتاريخ {signedTime}',
    IBAgreement: 'اتفاقية الوسيط المعرّف (IB)',
    confirmAgreement: 'يرجى التواصل مع مدير الوسيط المعرف IB لتأكيد اتفاقية الوسيط المعرف IB',
  },
};

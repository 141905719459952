import { endAjaxLoading } from '@/util/loading'
import { Message } from 'element-ui'
import i18n from '@/lang/index'
import config from '@/config'
import helper from '@/util/signinHelper.js'
import { setCookies, getCookies } from '@/util/cookies'
import { getLocalLoading } from "@/axios";
import store from '@/store'
import { HTMLdecode } from '@/util/utils';
import { FILTER_URLS } from '@/constants/filterUrls.js';
function respHandler(resp) {
  if (!getLocalLoading(resp.config.url)) endAjaxLoading()

  //if response is session_timeout, logout.
  if (resp.data.code === 'session_timeout') helper.signOut('logout')
  else {
    //if response isn't session_timeout, then update token.
    const token = getCookies('token')
    setCookies('token', token, 30)
  }

  if (resp.data.hasOwnProperty('code') && resp.data.code != 0 && isShowMessage(resp.data.code)) {
    let respMsg = getResponseMsg(resp.data.code)
    //if response code: 5xx, show error msg
    if ([637, 638, 639].includes(resp.data.code)) {
      //638, 639新增两个出金限制的code不做msg提示
      return resp
    }
    if (resp.data.code >= 500) {
      Message({
        message: respMsg,
        type: 'error',
      })
    }
    //if response code: 4xx, show warning msg
    else if (resp.data.code >= 400) {
      Message({
        message: respMsg,
        type: 'warning',
      })
    }
  }
  if (resp.data && FILTER_URLS) {
    for (let i = 0; i < FILTER_URLS.length; i++) {
      if (resp.config.url.includes(FILTER_URLS[i])) {
        resp.data = HTMLdecode(resp.data)
        break;
      }
    }
  }
  return resp
}
function getResponseMsg(code) {
  const email = store.state.regulator.contactEmail || config.info.getEmail(store.state.common.regulator)
  return i18n.t('responseMsg.' + code, { email: email })
}

function isShowMessage(code) {
  const codeList = [647, 1201, 1500]
  return !codeList.includes(code)
}
export default respHandler

const fsa = require('@/regulator/fsa.js')
const svg = require('@/regulator/svg.js')

const regulator = {
  fsa,
  svg,
}
module.exports = {
  cpUrl: 'http://myaccount.puprime.com/',
  info: {
    copyright: '2020',
    fullName: 'PU PRIME',
    entityName: 'PUPRIME Group',
    contactName: 'Support Team',
    getUrl(reg) {
      return reg ? regulator[reg].info.url : ''
    },
    getMap(reg) {
      return regulator[reg].info.map
    },
    getAddress(reg) {
      return regulator[reg].info.address
    },
    getPhone(reg) {
      return regulator[reg].info.phone
    },
    getEmail(reg) {
      return reg ? regulator[reg].info.email : ''
    },
    getAccountEmail(reg) {
      return reg ? regulator[reg].info.accountEmail : ''
    },
    getReferralSite(reg) {
      return reg ? regulator[reg].info.referralSite : ''
    },
    referralRebateSite(reg) {
      return reg ? regulator[reg].info.referralRebateSite : ''
    },
    getBlacklistEmail(reg) {
      return reg ? regulator[reg].info.blacklistEmail : ''
    },
  },
  getMenus(reg) {
    return reg ? regulator[reg].menus : []
  },
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard',
    2: 'Prime',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Islamic Standard',
    7: 'Islamic Prime',
    8: 'PRO ECN',
    9: 'VIP STP',
    10: 'PAMM', // mt4
    101: 'MT5 Standard STP',
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Standard',
    14: 'Prime',
    15: 'Islamic Standard',
    16: 'Islamic Prime',
    17: 'MT5 PRO ECN',
    19: 'Cent',
    20: 'Islamic-Cent',
    22: 'Prime Cent',
    23: 'Islamic Prime Cent',
    24: 'Cent', //mt5 Standard Stp Cent
    25: 'Islamic-Cent', //mt5 Islamic Cent
    26: 'Prime Cent', //mt5 Prime Cent
    27: 'Islamic Prime Cent', //mt5 Islamic Prime Cent
    28: 'PAMM', // mt5
    31: 'Pro Standard', // MT4 pro 1000
    32: 'MT5 Pro Standard', // MT5 pro 1000
    33: 'ECN',
    34: 'MT5 ECN',
    99: 'Copy Trading'
  },
  country_transferOptions: [
    {
      id: 2,
      value: 'common.country.international',
    },
  ],
  chartColor: ['rgb(242, 16, 17)', 'rgb(0, 76, 178)', 'rgb(9, 208, 220)', 'rgb(108, 125, 231)', 'rgb(18, 174, 223)'],
  allowIBTWithdrawal({ reg, countryCode, type }) {
    const { ALLOW_ALL, WHITELIST } = regulator[reg].internationalBankTransfer[type]

    if (ALLOW_ALL) {
      if (typeof WHITELIST !== 'undefined') {
        if (typeof WHITELIST[countryCode] !== 'undefined' && !WHITELIST[countryCode]) {
          return false
        }
      }
      return true
    }
    return WHITELIST[countryCode]
  },
  withdrawalDefaultAmountMin: 40,
  usdRateFromApiCurrencyList: ['JPY', 'HKD', 'USC'], // currencies whitch calling api to get rate (USD to specific currency)
  registerId3Countries(reg) {
    return regulator[reg]?.registerId3Countries
  },
  registerId3NeedAdditionalForm(reg) {
    return regulator[reg]?.registerId3NeedAdditionalForm
  },
}

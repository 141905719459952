import { instance, instanceExternal } from '@/axios/index.js'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'
const securitys = `${baseUrl}security/getSecuritys/`

const loginUrl = `${baseUrl}login/`
const fileUrl = `${baseUrl}file/`
const homeUrl = `${baseUrl}home/`
const withdrawalUrl = `${baseUrl}withdrawal/`

const transferUrl = `${baseUrl}transfer/`
const transferCPUrl = `${cpUrl}transfer/`
const contactUrl = `${baseUrl}contact/`
const commissionUrl = `${baseUrl}commission/`
const tradeaccountUrl = `${baseUrl}tradeaccount/`
const TradeRebateVolumeUrl = `${baseUrl}TradeRebateVolume/`
const foldlineUrl = `${baseUrl}foldline/`
const pieUrl = `${baseUrl}pie/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const withdrawalUrlCp = `${cpUrl}withdrawal/`
const userProfileUrl = `${baseUrl}userProfile/`
const ibreportUrl = `${baseUrl}ibreport/`
const reportUrl = `${baseUrl}report/`
const rebateUrl = `${baseUrl}rebate/`
const ibaccountUrl = `${baseUrl}ibaccount/`
const menuUrl = `${baseUrl}menu/`
const unfundedaccountUrl = `${baseUrl}unfundedaccount/`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCKuPDmPYUTdOFUitGsQzH3rLgs58B4_I0`
const notificationUrl = `${baseUrl}notification/`
const bannerUrl = `${baseUrl}banner/`
const systemUrl = `${baseUrl}system/`
const paymentSelectionUrl = `${baseUrl}payment/`
const profileUrl = `${cpUrl}profile/`
const mfaUrl = `${baseUrl}mfa/`
const identifyProofUrl = `${cpUrl}identityProof/`
const subAccount = `${baseUrl}sub-account/`

export const verificationUrl = `${baseUrl}verificationV2/`
export const personalDetailUrl = `${baseUrl}personalDetail/`
export const questionUrl = `${baseUrl}question/`

export const securityUrl = `${cpUrl}multiFactorAuth/`

/** CPS */
export const cpsWithdrawalUrl = `${withdrawalUrl}`

export const partialRequest = [`${ibaccountUrl}nextLevelTradingAccountProfit`]

/** login */
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_logout = () => instance.get(`${loginUrl}to_logout`)
export const apiGet_cp_url = () => instance.get(`${loginUrl}get_cp_url`)

/** home */
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))
export const apiQueryRebatesList = () => instance.get(`${homeUrl}queryRebatesList`)
export const apiQueryRebatesBlackList = () => instance.get(`${homeUrl}queryRebatesBlackList`)
export const apiQueryAvailableBalance = data => instance.post(`${homeUrl}queryAvailableBalance`, qs.stringify(data))
export const apiQueryTotalCommission = data => instance.post(`${homeUrl}queryTotalCommission`, qs.stringify(data))
export const apiGetNewOpenUserTotal = data => instance.post(`${homeUrl}getNewOpenUserTotal`, qs.stringify(data))
export const apiGetNextLevelTradingAccountProfit = data =>
  instance.get(`${ibaccountUrl}nextLevelTradingAccountProfit?account=${data}`)

/** token */
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)
export const apiAnti_Reuse = () => instance.get(`${baseUrl}token/anti-reuse`)

/** withdrawal */
export const apiGetSecuritys = data => instance.get(`${securitys}`)

export const apiApplyWithdrawal = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal`, qs.stringify(data), { headers: { token: token } })
export const apiCardInformation = data => instance.get(`${withdrawalUrlCp}queryUnionpayCard`, { param: data })
export const apiGetWithdrawalData = data => instance.post(`${withdrawalUrl}getWithdrawalData`, qs.stringify(data))
export const apiQueryBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)
export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)

/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)
export const apiPaymentInfo = withdrawType => instance.get(`${paymentSelectionUrl}info/${withdrawType}/`)

/** transfer */
export const apiToApplyTransferView = data => instance.post(`${transferUrl}toApplyTransferView`, qs.stringify(data))
export const apiApplyTransfer = (data, token) =>
  instance.post(`${transferUrl}applyTransfer`, qs.stringify(data), { headers: { token: token } })
export const apiRebateTransferBlacklist = () => instance.get(`${transferCPUrl}queryBlacklist`)
//Get transferable currency based on selected account's currency
export const apiGetTransferableCurrency = data => instance.get(`/cp${transferUrl}currencyList?formCurrency=${data}`)
export const apiGetTransferToRate = data =>
  instance.get(`/cp${transferUrl}currencyRate?formCurrency=${data.formCurrency}&&toCurrency=${data.toCurrency}`)

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

/** contact */
export const apiOwnerinfo = () => instance.post(`${contactUrl}ownerinfo`)
export const apiContactinfo = data => instance.post(`${contactUrl}contactinfo`, qs.stringify(data))

/** commission */
export const apiApplyCommission = data => instance.post(`${commissionUrl}applyCommission`, qs.stringify(data))

/** tradeaccount */
export const apiGetNearestOpenAccount = data =>
  instance.post(`${tradeaccountUrl}getNearestOpenAccount`, qs.stringify(data))
export const apiGetPendingAccount = data => instance.post(`${tradeaccountUrl}getPendingAccount`, data)

export const apiCheckEquity = data => instance.get(`${accountUrl}check_equity?mt4Account=${data}`)

/** TradeRebateVolume */
export const apiQueryRebateVolumeList = data =>
  instance.post(`${TradeRebateVolumeUrl}queryRebateVolumeList`, qs.stringify(data))

/** foldline */
export const apiFoldline = (url, data) => instance.post(`${foldlineUrl}${url}`, qs.stringify(data))

/** pie */
export const apiQueryPieTotalVolumeByGoods = data =>
  instance.post(`${pieUrl}queryPieTotalVolumeByGoods`, qs.stringify(data))

/** ibreport */
export const apiQueryIbReportData = data => instance.post(`${ibreportUrl}queryIbReportData`, data)

/** report */
export const apiRebate_report = data => instance.post(`${reportUrl}rebate-report`, data)
export const apiPayer_trade_details = data => instance.post(`${reportUrl}rebate-report/payer-trade-details`, data)
export const apiGet_instruments = data =>
  instance.get(`${reportUrl}get-instruments`, {
    params: data,
  })

/** rebate */
export const apiRebate = (url, data) => instance.post(`${rebateUrl}${url}`, qs.stringify(data))
export const apiAgreement = rebateAccount => instance.get(`${rebateUrl}agreement/account/${rebateAccount}`)
export const apiConfirmAgreement = rebateAccount => instance.post(`${rebateUrl}agreement/sign/${rebateAccount}`)

/** ib account */
export const apiIbAccounts = (url, data) => instance.post(`${ibaccountUrl}${url}`, qs.stringify(data))

/** Trading List */
export const apiGetOpenList = data => instance.post(`${ibaccountUrl}open_trade_list`, qs.stringify(data))
export const apiGetClosedList = data => instance.post(`${ibaccountUrl}close_trade_list`, qs.stringify(data))

/** menu */
export const apiLeads = data => instance.post(`${menuUrl}leads`, data)

/** unfunded account */
export const apiGetAccsByUrl = (url, data) => instance.post(`${unfundedaccountUrl}${url}`, qs.stringify(data))

/** notification */
export const apiGetNotifications = ({ country }) => instance.get(`${notificationUrl}enabled?country=${country}`)

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/** profile */
export const apiInfo = () => instance.post(`${profileUrl}info`)

/** for all currencies rates */

export const apiQueryRateUSDByTargetCurrency = currency =>
  instance.post(`${depositUrl}queryRateUSDByTargetCurrency`, qs.stringify({ targetCurrency: currency }))

/** identityProof */
export const apiGetFirstAccountAuditStatus = () => instance.get(`${accountUrl}getFirstAccountAuditStatus`)
export const apiGet_last_identifiy_proof = (id, auditType = 1) =>
  instance.get(`${identifyProofUrl}get_last_identifiy_proof`, {
    params: {
      documentType: id,
      auditType,
    },
  })
export const apiAttach_new_document = data => instance.post(`${identifyProofUrl}attach_new_document`, data)
export const apiUpdate_user_id_address = data => instance.post(`${identifyProofUrl}updateUserIdAddress`, data)
export const apiVerification2IdentityProofs = data => instance.post(`${identifyProofUrl}verificationV2`, data)
export const apiDoId3 = data => instance.post(`${identifyProofUrl}doID3`, data)

/** profile */
export const apiUpdateSubAccount = data => instance.post(`${subAccount}edit`, data)
export const apiGetSubAccountList = () => instance.get(`${subAccount}list`)
export const apiPostSubAccountChange = data => instance.post(`${subAccount}enable`, data)
export const apiGetSubAccountHistoryList = ({ pageNo, pageSize, subId }) =>
  instance.get(`${subAccount}history/page?pageNo=${pageNo}&pageSize=${pageSize}&subId=${subId}`)
export const apiPostSubPasswordChange = data => instance.post(`${subAccount}reset-password`, data)

/**security */
export const apiPreValidateTotp = data => instance.post(`${securityUrl}totp/preValidate`, data)
export const apiPreValidateEmail = data => instance.post(`${securityUrl}email/preValidate`, data)
export const apiGetSecurityInfo = () => instance.get(`${securityUrl}info`)
export const apiGetMultiFactorAuth = functionCode => instance.get(`${securityUrl}${functionCode}/getMultiAuthMethod`)
export const apiGetHint = () => instance.get(`${securityUrl}hintInfo`)

/** banner */
// displayPlatform standadize with BE (BannerDisplayPlatformEnum) & Admin (displayPlatformEnum)
// displayPage standadize with BE (BannerDisplayPageEnum) & CP (popupBannerDisplayPageEnum)
export const apiGetBanners = ({ country, displayPage }) =>
  instance.get(`${bannerUrl}enabled?displayPlatform=1&country=${country}&displayPage=${displayPage}`)
export const apiSnoozeBanner = notificationIdList => instance.post(`${bannerUrl}snooze`, { notificationIdList })

export const apiEncryptPublicKey = (token = null) => instance.get(`${cpUrl}keyPair/pk?token=${token}`)

/**session */
export const apiGetSessionId = () => instance.get(`${baseUrl}tool/session-id`)

export default {
  en: 'Inglês',
  zh: '中文',
  home: {
    NewPortal: 'Novo Portal',
    SecureIBPortal: 'Portal seguro do IB',
    DASHBOARD: 'PAINEL DE CONTROLE',
    MARKETING_TOOLS: 'FERRAMENTAS DE MARKETING',
    IB_REPORT: 'Relatório IB ',
    REBATE_REPORT: 'RELATÓRIO DE COMISSÃO',
    IB_ACCOUNTS: 'CONTAS IB',
    PAYMENT_HISTORY: 'HISTÓRICO DE TRANSAÇÕES',
    IB_FAQ: 'IB Perguntas frequentes',
    RebateAccountNumber: 'Número da conta de comissão',
    totalBalance: 'balanço total',
    totalCommission: 'comissão total',
    APPLY_FOR_A_REBATE: 'SOLICITE A COMISSÃO',
    availableBalance: 'saldo disponível',
    WITHDRAW_REBATE: 'RETIRAR COMISSÃO',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'CONTA',
    MonthtoDate: 'Do mês até a data',
    UPDATE: 'ATUALIZAR',
    REBATE: 'COMISSÃO',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    select_ib: 'SELECIONE A CONTA IB',
    NET_FUNDING: 'FINANCIAMENTO LÍQUIDO',
    DEPOSITS: 'DEPÓSITOS',
    WITHDRAW: 'LEVANTAR',
    OPEND_ACCOUNT: 'CONTA ABERTA',
    PERFORMANCE: 'DESEMPENHO',
    OpendAccount: 'Conta Aberta',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MAIS NEGOCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CONTAS COM MELHOR DESEMPENHO PARA ESTE MÊS',
    RECENTLY_OPENED_ACCOUNTS: 'CONTAS ABERTAS RECENTEMENTE',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Conta',
    Name: 'Nome',
    Volume: 'Volume',
    Rebate: 'COMISSÃO',
    Balance: 'Saldo',
    ContactClient: 'Cliente de contato',
    YouCannotAskForCommission: 'Você não pode pedir uma comissão',
    applicationFailedPleaseTryAgain: 'O aplicativo falhou, tente novamente mais tarde',
    applyRebateSuccessTip:
      'Parabéns, recebemos sua solicitação de reembolso. | {montante} será transferido para a conta de reembolso {conta} dentro de 1 dia útil. | Você pode sacar ou transferir fundos assim que seu reembolso for processado.',
    HOVER:
      'As comissões do dia anterior são calculados e processados ​​diariamente entre 9h e 11h AEST.',
    blacklist:
      'Esta conta não pode obter uma compensação no momento, por favor, contacte o seu gestor de conta.',
    ACCOUNTS_PROFIT:
      'Lucro/perda total histórico de todas as contas de negociação com esta conta IB. O carregamento pode demorar um pouco.',
    PROFIT: 'CONTAS LUCRO',
    reload: 'Recarregar',
  },
  liveChat: { header: 'Bate-papo ao vivo' },
  contactUs: {
    click: 'Clique',
    assist: ' CONTACTE-NOS',
    header: 'CONTATE-NOS',
    followUs: 'Siga-nos',
    tip: '{platform} a equipe profissional multilíngüe de suporte ao cliente está pronta para ajudá-lo e ajudar a proporcionar-lhe uma experiência comercial incomparável.',
    chatNow: 'Vamos Conversar Agora',
    globalOffice: 'Escritório Global',
  },
  ibAccounts: {
    header: 'CONTAS IB',
    ibAccount: 'Conta IB',
    subIbs: 'Conta Sub IB',
    tradinglist: 'Lista Comercial',
    openlist: 'Lista aberta',
    closedlist: 'Lista Fechada',
    tradinglistColumn: {
      ticketid: 'ID do bilhete',
      opentime: 'Tempo aberto',
      type: 'Modelo',
      volume: 'Volume',
      item: 'Item',
      openprice: 'Preço Aberto',
      closeprice: 'Preço de fechamento',
      closetime: 'Hora de fechamento',
      commission: 'Comissão',
      profit: 'Lucro',
      buy: 'Comprar',
      sell: 'Vender',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      verificationCode:
        'O código de verificação foi enviado para {email}. Por favor, entre em contacto com {supportEmail} se precisar de mais assistência',
      fetchTradeAccFailed: 'Falha na busca da Conta de Negociação',
      phone: 'Celular',
      evp: 'Chave Aleatória',
      failed: 'O levantamento falhou. Por favor, tente novamente mais tarde',
      tokenExpired: 'O token expirou',
    },
    alert:
      'Devido às leis AML, o método que você usa para retirar deve ser o mesmo que o método usado para seu depósito. Se o valor do saque for inferior a 100 unidades da moeda base de sua conta, será cobrada uma taxa de 20 unidades pelo banco para manuseio.',
    NetellerChargeAlert: 'Neteller cobra taxa de transação de {percentageFee}% para cada retirada.',
    FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
    SkrillChargeAlert: 'Skrill cobra taxa de transação de {percentageFee}% para cada retirada.',
    cryptoAlert:
      'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
    REBATE_WITHDRAW_REQUEST: 'SOLICITAR LEVANTAMENTO DE COMISSÃO',
    PleaseSelectDesiredWithdrawMethod: 'Selecione o método de levantamento desejado',
    BankName: 'Nome do banco',
    BankAccountsName: 'Nome das contas bancárias',
    FasaPayAccountName: 'Nome da conta FasaPay',
    AustralianBankName: 'Nome do banco australiano',
    BankAccountNumber: 'Número da conta de banco',
    FasaPayAccountNumber: 'Número da conta FasaPay',
    BankAccountBeneficiary: 'Beneficiário da conta bancária',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Endereço da carteira Bitcoin',
    USDTEmail: 'Tether Wallet Address',
    BankBranch: 'Agência Bancária',
    Province: 'Província',
    City: 'Cidade',
    BankAddress: 'Endereço do banco',
    bankCity: 'Cidade do Banco',
    bankProvince: 'Província do Banco',
    AccountNumberIBAN: 'Número da conta / IBAN',
    BankBeneficiaryName: 'Nome do beneficiário do banco',
    BankAccountBeneficiaryName: 'Nome do beneficiário da conta bancária',
    AccountHoldersAddress: 'Endereço do titular da conta',
    SelectWithdrawCard: 'Selecione o seu cartão de retirada',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift (contas não AUD)',
    ABA_SortCodeABA: 'ABA / Código de classificação',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Seu pagamento é maior que o saldo disponível',
    CannotUseMoreThanTwoDecimalValues:
      'Você não pode usar números e números negativos com mais de duas casas decimais',
    YourPaymentIsBeingProcessed: 'Seu pedido de levantamento foi enviado',
    Itcannotbe0: 'Não pode ser 0',
    noCardWarn:
      'Você não tem nenhum cartão disponível para fazer levantamentos, primeiro vincule seu cartão aos Detalhes de saque',
    amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a {minLimit} {currency}',
    blackList:
      'Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações',
    status: {
      withdrawalSubmitted: 'Submetido',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Em processamento',
      withdrawalRejected: 'Rejeitado',
      withdrawalAccepted: 'Em processamento',
      withdrawalPayFailed: 'Falhou',
      withdrawalSuccess: 'Processado',
      withdrawalFailed: 'Falhou',
      withdrawalPending: 'Pendente',
      withdrawalPartialFailed: 'Falhou',
      transferSubmitted: 'Em processamento',
      transferSuccess: 'Processado',
      transferRejected: 'Rejeitado',
      transferFailed: 'Falhou',
      transferProcessing: 'Em processamento',
      withdrawalReversedFailed: 'Falha Revertida',
      withdrawalPartialSuccess: 'Sucesso Parcial',
    },
    formErrorMsg: {
      bankName: 'Nome do banco é obrigatório',
      bsb: 'BSB é obrigatório',
      beneName: 'O nome do beneficiário do banco é obrigatório',
      accNum: 'O número da conta bancária é obrigatório',
      swift: 'Swift é necessário',
      bankAddress: 'O endereço do banco é obrigatório',
      holderAddress: 'O endereço do titular é obrigatório',
      amt: 'Quantidade é necessária',
      firstSixDigits: 'Digite os 6 primeiros dígitos',
      lastFourDigits: 'Insira os 4 últimos dígitos',
      branchName: 'Nome da filial é obrigatório',
      email: 'E-mail é obrigatório',
      accName: 'O nome da conta é obrigatório',
      province: 'A província do banco é necessária',
      city: 'Cidade do banco é obrigatória',
      bankCard: 'Selecione um cartão',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      amt0: 'A quantidade não pode ser 0',
      ifscCode: 'IFSC Code is required',
      bitcoin: 'O endereço da Carteira Bitcoin é obrigatório',
      addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      usdt: 'O endereço da Carteira USDT é obrigatório',
      beneficiaryNameReq: 'O nome do beneficiário do banco é obrigatório',
    },
    transfer: {
      info1:
        'A {platform}  prescinde do pagamento da comissão bancária no primeiro levantamento do mês. Levantamentos adicionais no mesmo mês irão incorrer numa taxa bancária de 20 unidades da sua moeda de levantamento (Para USC/HKD/JPY, a taxa será equivalente a 20 USD)',
      info2:
        'Por favor, certifique-se de que o Nome do Beneficiário do Banco é preenchido exatamente como no passaporte (abreviatura proibida, preencha o nome completo de acordo com a inicial no extrato)',
      info3:
        'É preferível ter uma conta bancária com o formato IBAN. Se não for da região da UE, por favor, preencha exatamente com o extrato bancário',
      label: {
        upload:
          'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
        upload2:
          'Certifique-se de que a data do extrato ou a data da última transação esteja dentro do mês atual até os últimos três meses. Se ultrapassar esse período, por favor, faça o reenvio do extrato.',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
      minAmount: 'O valor mínimo de retirada é 100 {currency}',
    },
    transitNumber: 'Número de Trânsito',
    institutionNumber: 'Número da Instituição',
    beneficiaryName: 'Nome do beneficiário',
    LBTchannelsNote:
      'Nota: O\n  nome da conta bancária/nome do beneficiário deve coincidir com o nome da sua\n  conta bancária.',
  },
  security: {
    contactSupport:
      'Não é possível acessar o método de autenticação? Por favor, entre em contato com {contact_email} ou Livechat',
    securityAuthenticator: 'Autenticação de Segurança',
    verificationRequired: 'Por favor, insira o código de verificação',
    resend: 'Reenviar',
    authenticatorTooltip:
      'O código de verificação do aplicativo Authenticator é atualizado a cada 30 segundos',
    verifyPreEmailNote: 'O código será enviado para {email}',
    verifyPostEmailNote: 'Código enviado para {email}',
    enableNow: 'Ativar agora',
    verificationTitle: 'Verificação necessária',
    verificationDesc:
      'Por favor, ative o aplicativo Authenticator de Segurança antes de prosseguir.',
  },
  rebate: {
    status: { processed: 'Processado', processing: 'Em processamento', rejected: 'Rejeitado' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PEDIDO DE TRANSFERÊNCIA DE COMISSÃO',
    transferAmountIsGreaterThanAvailableBalance:
      'O valor da sua transferência é maior do que o saldo disponível',
    yourRebateTransferHasBeenProcessed: 'sua transferência de reembolso foi enviada',
    PleaseEnterTransferInformation: 'Insira informações de transferência precisas',
    tradingAccount: 'Conta de negociação',
    rebateAccount: 'Conta de comissão',
    account: 'Conta',
    blackList:
      'Esta conta foi restringida de fazer transferências entre contas, por favor, contacte {supportEmail}',
  },
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    lang: {
      english: 'Inglês',
      chinese: 'chinês',
      french: 'francês',
      thai: 'tailandês',
      german: 'alemão',
      spanish: 'espanhol',
      malay: 'malaio',
      viet: 'vietnamita',
      indo: 'indonésio',
      arabic: 'árabe',
    },
    button: {
      iKnow: 'Eu entendo',
      confirmCamelCase: 'Confirmar',
      confirm: 'CONFIRME',
      submit: 'Submeter',
      cancel: 'CANCELAR',
      sendCode: 'Enviar Código',
      resendCode: 'Reenviar código',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'As retiradas não são temporariamente suportadas',
      limitPassword:
        'Devido à modificação da sua senha de login, os saques não são temporariamente suportados por {day}dias para garantir a segurança da sua conta. Esta restrição deverá ser levantada em {hour} horas.',
      limitUserLogin:
        'Devido à modificação do seu e-mail/número de telefone de login, os saques não são temporariamente suportados por {day}dias. Esta restrição deverá ser levantada em {hour} horas.',
      limitSecurityAuthenticator:
        'Devido à modificação do seu aplicativo Autenticador de Segurança, os saques não são temporariamente suportados por {day} dias para garantir a segurança de sua conta. Essa restrição deve ser removida em {hour} horas.',
      banktransfer: 'Transferência Bancária Internacional',
      aus: 'Transferência Bancária - Austrália',
      int: 'Transferência Bancária - Internacional',
      chn: 'Transferência Bancária - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferência UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Cartão de Crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Cartão de Crédito/Débito',
      bankwiretransferinternational: 'Transferência Bancária (Internacional)',
      banktransferaustralia: 'Transferência Bancária (Austrália)',
      banktransferinternational: 'Transferência Bancária Internacional',
      banktransferchina: 'Transferência Bancária (Internacional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferência de Corretora para Corretora',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Serviço Bancário Online (Tailândia)',
      internetbankingnigeria: 'Serviço Bancário Online (Nigéria)',
      internetbankingvietnam: 'Serviço Bancário Online (Vietname)',
      internetbankingmalaysia: 'Serviço Bancário Online (Malásia)',
      internetbankingindonesia: 'Serviço Bancário Online (Indonésia)',
      internetbankingkorea: 'Serviço Bancário Online (Coréia)',
      internetbankingindia: 'Serviço Bancário Online (India)',
      internetbankingphilippine: 'Serviço Bancário Online (Philippine)',
      internetbankingsouthafrica: 'Serviço Bancário Online (África do Sul)',
      internetbankinguganda: 'Serviço Bancário Online (Uganda)',
      internetbankingrwanda: 'Serviço Bancário Online (Ruanda)',
      internetbankingzambia: 'Serviço Bancário Online (Zâmbia)',
      internetbankingcongo: 'Serviço Bancário Online (Congo)',
      internetbankingcameroon: 'Serviço Bancário Online (Camarões)',
      internetbankingburundi: 'Serviço Bancário Online (Burundi)',
      internetbankingkenya: 'Serviço Bancário Online (Quênia)',
      internetbankingghana: 'Serviço Bancário Online (Gana)',
      internetbankingtanzania: 'Serviço Bancário Online (Tanzânia)',
      internetbankingcanada: 'Serviço Bancário Online (Canadá)',
      internetbankingbrazil: 'Serviço Bancário Online (Brasil)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferência Bancária do Vietnam',
      nigeriabanktransfer: 'Transferência Bancária da Nigéria',
      polipayment: 'Pagamento com Poli',
      bankwiretransferaustralia: 'Transferência Bancária (Austrália)',
      thailandinstantbankwiretransfer: ' Transferência Bancária Instantânea da Tailândia',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malaia',
      banktransferbpaypolipay: 'Transferência Bancária / BPay / PoliPay',
      nigeriainstantbankwiretransfer: 'Transferência Bancária da Nigéria',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea da Philippine',
      southafricainstantbankwiretransfer: 'Transferência Bancária Instantânea da África do Sul',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      indiabanktransfer: 'Transferência Bancária da India',
      southkoreabanktransfer: 'Transferência bancária instantânea da Coreia do Sul',
      ugandabanktransfer: 'Transferência de banco local de Uganda',
      rwandabanktransfer: 'Transferência de banco local de Ruanda',
      zambiabanktransfer: 'Transferência de banco local da Zâmbia',
      congobanktransfer: 'Transferência de banco local do Congo',
      cameroonbanktransfer: 'Transferência de banco local de Camarões',
      burundibanktransfer: 'Transferência bancária local do Burundi',
      kenyabanktransfer: 'Transferência bancária local do Quênia',
      ghanabanktransfer: 'Transferência de banco local de Gana',
      tanzaniabanktransfer: 'Transferência de banco local da Tanzânia',
      brazilbanktransfer: 'Transferência bancária no Brasil',
      transfer: 'Transferência para contas',
      banktransferbpaypolipayaustralia: 'Transferência Bancária/BPay/PoliPay (Austrália)',
      banktransferbpaypolipayinternational: 'Transferência Bancária/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptomoeda-Bitcoin',
      cryptotetheromni: 'Criptomoeda-Tether(OMNI)',
      cryptotethererc20: 'Criptomoeda-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      cryptocurrencyethcps: 'Criptomoeda-ETH',
      cryptocurrencyusdccps: 'Criptomoeda-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRIPTOCURRÊNCIA',
      LOCAL_TRANSFER: 'TRANSFERÊNCIA LOCAL',
      japanbanktransfer: 'Transferência bancária no Japão',
      internetbankingmexico: 'Serviço Bancário Online (México)',
      internetbankinguae: 'Internet Banking (EAU)',
      ewallet: 'E-Wallet',
      internetbankingtaiwan: 'Banco na Internet (Taiwan)',
      internetbankingeu: 'Banco na Internet (UE)',
      cryptousdttrc20: 'Criptomoeda-USDT(TRC20)',
      momopay: 'Momopay',
    },
    country: { international: 'Internacional', australia: 'Austrália' },
    field: {
      country_region: 'País / Região',
      address: 'Endereço',
      phone: 'Número de telefone',
      nat: 'Nacionalidade',
      country: 'País de residência',
      save: 'Investimento',
      annIncome: 'Rendimento Anual',
      emailAdd: 'Endereço de E-mail',
      yes: 'Sim',
      no: 'Não',
      enterVerificationCode: 'Insira o Código de Verificação',
      ebuyAccName: 'Nome da conta EBuy',
      ebuyEmailAddress: 'Endereço de e-mail da EBuy',
      skrillAccName: 'Nome da conta Skrill',
      skrillEmailAddress: 'Endereço de e-mail da Skrill',
      netellerAccName: 'Nome da conta Neteller',
      netellerEmailAddress: 'Endereço de e-mail da Neteller',
      imptNotes: 'Notas importantes',
      paymentAccName: '{name} Nome da Conta',
      paymentAccNum: '{name} Número de Conta',
      documentType: 'Tipo de documento',
      accDigit: 'Número de Conta',
      docID: 'ID do Documento',
      bankBranchCode: 'Código de sucursal',
      accType: 'Tipo de Conta',
      accName: 'Nome da Conta',
      accNum: 'Número de Conta',
      bankName: 'Nome do Banco',
      swiftCode: 'Código SWIFT',
      bankAddress: 'Endereço do Banco',
      bankHolderAddress: 'Endereço do Titular da Conta',
      bankBeneficiaryName: 'Nome do Beneficiário Bancário',
      bankAccNum: 'Número de Conta Bancária',
    },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DE',
      UPDATE: 'ATUALIZAR',
      TO: 'PARA',
      DATE: 'DATA DE ABERTURA',
      pleaseSelect: 'Por favor selecione',
      amount: 'Montante',
      rebateAccount: 'Conta de comissão',
      comingSoon: 'em breve',
      tradingHistory: 'HISTÓRIA DE NEGOCIAÇÃO',
      noData: 'Sem dados',
      NAME: 'NOME',
      EMAIL: 'O EMAIL',
      ACCTYPE: 'TIPO DE CONTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MOEDA BASE',
      DATERANGE: 'INTERVALO DE DATA',
      STATUS: 'ESTADO',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DA CONTA',
      BALANCE: 'SALDO',
      PROFITLOST: 'LUCRO',
      MARGINLVL: 'NIVEL DE MARGEN',
      ACCEQUITY: 'PATRIMONIO DE LA CUENTA',
      CREDIT: 'CRÉDITO',
      NONE: 'Nenhum',
      ALL: 'Todos',
      actualAmonut: 'montante atual',
      currency: 'MOEDA',
      method: 'MÉTODO',
      REGISTRATION: 'DATA DE REGISTO',
      FTD: 'DATA DE DEPÓSITO',
      CURRENTCAMPAIGN: 'CAMPANHA ATUAL',
      blacklisted: 'Lista Negra',
      FIRST_TRANS_IN: 'PRIMEIRA TRANS EM DATA',
      createTime: 'Hora de submissão',
      lastUpdateTime: 'Tempo processado',
    },
    dateRange: {
      today: 'Hoje',
      yesterday: 'Ontem',
      lastSevenDays: 'Últimos 7 dias',
      lastThirtyDays: 'Últimos 30 dias',
      weekToDate: 'Semana até a data',
      monthToDate: 'Do mês até a data',
      quarterToDate: 'Trimestre até a data',
      previousWeek: 'Semana anterior',
      previousMonth: 'Mês anterior',
      previousQuarter: 'Trimestre Anterior',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRIPTO' },
    formValidation: {
      dynamicReq: '{dynamic} é obrigatório',
      verCodeReq: 'O código de verificação é obrigatório',
      alphanumericReq: 'Por favor, insira o alfanumérico',
      answerReq: 'Por favor, selecione',
      sameAsPOA: 'A entrada tem de ser a mesma que o POA',
      sameAsID: 'A entrada deve ser igual à Identificação',
      notBlank: 'Deixar em branco se não for aplicável',
      digits12WithoutDash: 'Por favor, digite 12 dígitos, sem "-".',
      enterAsPerId: 'Digite conforme o ID',
      numberReq: 'Por favor, insira o número',
      addressReq: 'Por favor, insira a sua morada',
      suburbReq: 'Por favor, insira a sua cidade ou freguesia',
      stateReq: 'O distrito ou concelho é obrigatório',
      postcodeReq: 'Por favor, insira o seu código postal',
      countryReq: 'Por favor, selecione o seu país de residência',
      AlphanumericOnlyNotSpace: 'Apenas alfanumérico. Espaço não é permitido.',
      AlphanumericOnly: 'Apenas alfanumérico.',
      NumbersSymbol: 'Apenas números e símbolos.',
      NumbersOnlyNotSpace: 'Apenas números. Espaço não é permitido.',
      NumbersOnly: 'Apenas números.',
      LettersSymbol: 'Apenas letras e símbolos.',
      LettersSymbolNotSpace: 'Apenas letras e símbolos. Espaço não é permitido.',
      LettersOnlyNotSpace: 'Apenas letras. Espaço não é permitido.',
      NotSpace: 'Espaço não permitido.',
    },
  },
  report: {
    title: { rebateReport: 'RELATÓRIO DE COMISSÃO' },
    rebate: {
      column: {
        name: 'NOME',
        accountNumber: 'NÚMERO DA CONTA',
        accountType: 'TIPO DE CONTA',
        volume: 'Volume',
        rebate: 'COMISSÃO',
        totalRebate: 'COMISSÃO TOTAL',
        date: 'DATA',
        instrument: 'INSTRUMENTO',
      },
      dropdown: {
        allInstruments: 'Todos os instrumentos',
        all: 'Todos',
        instruments: 'Instrumentos',
      },
      others: {
        rebate: 'COMISSÃO',
        instrumentsTraded: 'INSTRUMENTOS NEGOCIADOS',
        lots: 'quantidade',
        totalRebate: 'Desconto Total',
        rebates: 'COMISSÃO',
      },
    },
  },
  ibReport: {
    header: 'Relatório IB ',
    netFunding: 'FINANCIAMENTO LÍQUIDO',
    deposits: 'DEPÓSITOS',
    withdraw: 'LEVANTAR',
    openedAccs: 'CONTAS ABERTAS',
    funding: 'FINANCIAMENTO',
    withdrawals: 'LEVANTAR',
    appliedAmount: 'quantidade aplicada',
    depositAmount: 'Valor do depósito',
    withdrawalAmount: 'Quantidade retirada',
    timeLimitMessage:
      'O intervalo máximo de consulta para download é de 90 dias, por favor atualize o novo intervalo de datas',
    successInfo: 'Download bem-sucedido',
    download: 'Descarregar',
    comfirm: 'Confirmar',
    defaultTimeMessage: 'O intervalo máximo de consulta para download é de 90 dias',
  },
  lead: { header: 'LEADS', ibAccount: 'Conta IB', demoAccounts: ' CONTAS DEMO' },
  pendingAccounts: {
    header: 'CONTAS PENDENTES',
    errorDoc:
      'Os seguintes candidatos preencheram o formulário de inscrição online, mas ainda precisam fornecer prova de identificação (ID) para finalizar sua conta.',
    acceptedDocType: 'As formas de identificação aceitas incluem:',
    primaryDoc: 'ID primária',
    passport: 'Uma cópia do seu passaporte internacional válido (página de assinatura)',
    id: 'Uma cópia da carteira de identidade nacional emitida pelo governo',
    dl: 'Uma cópia de sua carteira de motorista válida',
    address: 'ID Secundário',
    addressList: [
      'Cópia de uma conta de serviço público recente (não mais de 6 meses)',
      'Cópia do extrato do cartão de crédito (com menos de 6 meses)',
      'Cópia de um extrato bancário (não mais de 6 meses)',
    ],
    tip: 'Incentive os candidatos a fornecer uma cópia de UM dos documentos de identidade primários e secundários aceitos para',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTÓRICO DE TRANSAÇÕES',
    rebateHistory: 'COMISSÃO',
    withdrawHistory: 'LEVANTAMENTOS',
    transferHistory: 'TRANSFERÊNCIAS',
    cancelWithdraw: 'Deseja Cancelar a Solicitação de Retirada?',
    cancelError:
      'Sua retirada está sendo processada no momento. Portanto, não podemos aceitar sua solicitação de cancelamento. Para obter mais informações, você pode entrar em contato conosco usando o recurso de bate-papo ao vivo.',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de ficheiros suportados: {files}',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    selectFile: 'Selecionar Ficheiro',
    maxFileSize: 'O tamanho do ficheiro carregado não pode exceder {maxSize}MB',
    sureToDelete: {
      title: 'Tem a Certeza',
      content: 'Tem a certeza de que pretende apagar o ficheiro carregado？',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'Em Processamento',
      paid: 'Pago',
      unpaid: 'Por pagar',
      rejected: 'Rejeitado',
    },
  },
  referralLinks: {
    header: 'LINKS DE REFERÊNCIA',
    ibAcc: 'Conta IB',
    campLang: 'Idioma da campanha',
    cpy: 'CÓPIA DE',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Conta Real',
    demoAcc: 'Conta Demo',
    homePage: 'Pagina inicial',
    Share: 'Compartilhar',
    subIbReferral: 'Referência de Sub IB',
  },
  settings: { ibProfile: 'PERFIL IB ', info: 'INFORMAÇÃO', reg: 'REGISTADO EM' },
  unfundedAcc: {
    header: 'CONTAS NÃO FINANCIADAS',
    allActivationAccs: 'TODAS AS CONTAS DE ATIVAÇÃO',
    neverFundedAccs: ' CONTAS NUNCA FINANCIADAS',
    prevFundedZero: 'SALDO ZERO',
  },
  multiib: { level: 'NÍVEL {level}', noib: 'Não há sub-IBs sob esta conta.' },
  header: { bckToCP: 'VOLTAR AO PORTAL DO CLIENTE', logout: 'SAIR' },
  menu: {
    dashboard: 'PAINEL DE CONTROLE',
    accManagement: 'CONTAS',
    ibReport: 'Relatório IB ',
    rebateReport: 'RELATÓRIO DE COMISSÃO',
    iblevel: 'MULTI-NÍVEL',
    ibAccs: 'CONTAS ATIVADAS',
    leads: 'LEADS',
    pendingAccs: 'CONTAS PENDENTES',
    unfundedAccs: 'CONTAS NÃO FINANCIADAS',
    payHistory: 'HISTÓRICO DE TRANSAÇÕES',
    ibProfile: 'PERFIL IB ',
    refLinks: 'LINKS DE REFERÊNCIA',
    contactUs: 'CONTATE-NOS',
    switchCP: 'MUDAR PARA O PORTAL DO CLIENTE',
  },
  footer: {
    riskWarn:
      'Aviso de risco: a negociação de contratos por diferença (CFDs) carrega um alto nível de risco para o seu capital e pode resultar em perdas. Você só deve negociar com dinheiro que pode perder. A negociação de CFDs pode não ser adequada para todos os investidores, certifique-se de compreender totalmente os riscos envolvidos e de tomar as medidas adequadas para gerenciá-los. Leia o documento relevante de divulgação de riscos com atenção.',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    431: 'Cálculo do desconto em curso; tente novamente o seu pedido dentro de algum tempo',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    530: 'Sessão de login inválida',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    553: 'Não pode levantar/transferir devido ao crédito na sua conta. Por favor, entre em contacto com {email} para obter mais ajuda',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    582: 'Você está participando do evento e possui vagas, portanto não pode enviar um pedido de retirada.',
    584: 'Não tem compensações aplicáveis',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    595: 'Como você está participando de nossas atividades, sua solicitação de transferência não pode ser processada no momento.',
    647: 'O código de verificação está incorreto',
    1101: 'Existem 10 contas, não é possível adicionar mais.',
    1102: 'Não foi possível ativar, a conta foi criada como uma conta real ou demo.',
    1103: 'A conta foi desativada',
    1202: 'Verificação expirada, por favor tente novamente',
    1204: 'Por favor, tente com outro endereço de e-mail',
    1208: 'Os códigos de verificação não podem ser enviados repetidamente',
    1209: 'Erro ao enviar o código de verificação',
    session_timeout:
      'A sessão chegou ao tempo limite, você será redirecionando para a página de login.',
  },
  verificationStatus: {
    title: { default: 'Está Quase a Terminar', LV3: 'Bem-vindo' },
    dueToAML:
      'Está pronto para começar a negociar em breve! Ajude-nos a proteger a sua conta verificando as suas informações pessoais.',
    accOpeningVerification: {
      title: 'Criar Conta Real',
      content: 'Crie uma Conta Real para começar a financiar a sua conta.',
    },
    identityVerification: {
      title: 'Verificação de Identidade',
      content: 'Submeta e verifique a sua prova de identidade para começar a negociar.',
    },
    poaVerification: {
      title: 'Verificação de Morada',
      content: 'O POA tem de ser verificado antes de se poder realizar um levantamento.',
    },
    kyc: {
      depositNow: 'Deposite Agora',
      withdrawNow: 'Levantar Agora',
      createNow: 'Criar agora',
      start: 'Começar',
      complete: 'Concluído',
      pending: 'Pendente',
      rejected: 'Rejeitado',
      resubmit: 'Voltar a submeter',
    },
    resubmitNote:
      'Os seus documentos podem estar em falta ou estar incorretos. Por favor, reenvie.',
    rejectedNote: 'Questões? Por favor, contacte-nos através do {liveChat} .',
    liveChat: 'Chat ao Vivo',
    uploadTemplate: {
      idGuideTitle: 'Orientações para Documentos de Identificação',
      uploadGuideTitle: 'Orientações de Carregamento',
      desc1: 'Emitido pelo Governo',
      desc2: 'Documentos originais em tamanho real, não editados',
      desc3: 'Coloque os documentos sobre um fundo de uma só cor',
      desc4: 'Imagem legível, bem iluminada e a cores',
      desc5: 'Sem imagens a preto e branco',
      desc6: 'Sem documentos editados ou caducados',
      bottomDesc:
        'O ficheiro carregado pode ser jpg, png ou pdf, e o tamanho do ficheiro não pode exceder 5MB',
    },
  },
  register: {
    progressStatus: {
      title: 'Progresso da verificação',
      estimatedTimeTitle: 'Tempo Estimado para Conclusão:',
      min: 'minutos',
    },
    tabs: {
      personalDetails: 'Detalhes Pessoais',
      moreAboutYou: 'Mais Sobre Si',
      accountConfig: 'Configuração da Conta',
      confirmYourId: 'Confirme a sua Identidade',
      fundYourAcc: 'Financiar a sua Conta',
      personalInformation: 'Informação Pessoal',
      createLiveAccount: 'Criar Conta Real',
      financialInfo: 'Informação Financeira',
      iDProof: 'Comprovativo de Identidade',
      addressProof: 'Comprovativo de Morada',
    },
    btn: {
      next: 'PRÓXIMO',
      back: 'VOLTAR',
      fundnow: 'FINANCIAR AGORA',
      upload: 'Carregar',
      done: 'CONCLUÍDO',
      bTCP: 'Voltar ao Portal do Cliente',
      proceedToUpload: 'Avançar para o Carregamento',
      completeID: 'Conclua a Verificação de Identidade',
      completePOA: 'Conclua a Verificação de POA',
      depositViaOther: 'Depositar Através de Outro Canal',
      withdrawViaOther: 'Sacar por outro canal',
    },
    authority: {
      dialog1: 'Deve ter uma conta real para aceder a esta secção do portal do cliente.',
      dialog2:
        'Abra uma conta real agora para desbloquear o acesso total ao portal do cliente, o que inclui uma variedade de ferramentas de negociação, promoções e muito mais.',
      openLiveAcc: 'abrir conta real',
    },
    demo: {
      congrad: 'Parabéns!',
      title: 'A sua conta DEMO MT{platform} de 60 dias está agora ativa',
      title2: 'Detalhes da sua Conta de DEMONSTRAÇÃO',
      li: { login: 'Login: ', password: 'Password: ', srv: 'Server: ', expDate: 'Expiry Date: ' },
      emailSent:
        'Os detalhes de início de sessão também foram enviados para o seu e-mail registado.',
      dl: 'Descarregue a sua Plataforma de Negociação MT{platform}',
      improveQ: 'Quer melhorar a sua negociação？',
      improveWay:
        'Basta abrir uma conta real em apenas alguns minutos para desbloquear uma variedade de ferramentas de negociação e promoções exclusivas.',
      proTrade: 'Ferramentas Pro Trader',
      depositBonus: 'Bónus de Depósito de 20%',
      smartTradeTool: 'Ferramentas Smart Trader',
      openLiveAcc: 'abrir conta real',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Detalhes Pessoais',
        userTitle: 'Título',
        firstName: 'Nome Próprio',
        lastName: 'Apelido',
        email: 'E-mail',
        dob: 'Data de Nascimento',
        idType: 'Tipo de Identificação',
        referral: 'Foi recomendado por alguém？ (opcional)',
        middleName: 'Nome do Meio',
        nationality: 'Nacionalidade',
        phoneNum: 'Número de Telefone',
        date: 'Dia',
        month: 'Mês',
        year: 'Ano',
        placeOfBirth: 'Naturalidade',
        idNum: 'Número de Identificação',
        passportNum: 'Número do Passaporte',
        driverLicenseNum: 'Número da Carta de Condução',
        titleDropdown: {
          mr: 'Sr.',
          mrs: 'Sra.',
          ms: 'Sra.',
          miss: 'Srta.',
          dr: 'Dr.',
          prof: 'Professor',
        },
        idTypeDropdown: {
          passport: 'Passaporte',
          idCard: 'Cartão Nacional de Identidade',
          driverLicense: 'Carta de Condução',
        },
      },
      page2: {
        pageTitle: 'ENDEREÇO DE RESIDÊNCIA PRINCIPAL',
        mainResidency: 'País de residência principal',
        streetNumber: 'Número na Rua',
        streetName: 'Nome de Rua',
        address: 'Endereço',
        provinceOrState: 'Concelho / Distrito',
        cityOrSuburb: 'Cidade / Freguesia',
        postcode: 'Código Postal',
        usQuestion:
          'É Cidadão/Residente nos EUA para fins fiscais ou possui ligações/laços nos EUA (por exemplo, conta Bancária etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'DETALHES FINANCEIROS E DA SITUAÇÃO PROFISSIONAL',
      pageTitle2: 'Conhecimento e Experiência de Negociação',
      complianceExplain:
        'As seguintes perguntas são recolhidas para conformidade com os requisitos da AML/CTF.',
    },
    accountConfig: {
      pageTitle: 'Configuração da Conta',
      chooseTradingPlatform: 'Escolha a Plataforma de Negociação',
      chooseAccType: 'Escolha o Tipo de Conta',
      chooseAccCurrency: 'Escolha a Moeda da Conta',
    },
    confirmYourId: {
      pageTitle: 'Confirme a sua Identidade',
      description:
        'Como corretora forex regulamentada, somos obrigados a verificar a sua identidade. Por favor, faça o carregamento de um dos seguintes documentos, para a verificação do seu Comprovativo de Identificação e Comprovativo de Morada. Como alternativa, pode enviar os seus documentos de verificação por e-mail para {mailTo}',
      poidTitle: 'Comprovativo de Identificação',
      porTitle: 'Comprovativo de Endereço',
      uploadDescription: 'Escolha um tipo de documento e faça o carregamento em concordância',
      note: 'IMPORTANTE: O documento DEVE mostrar claramente o seu nome completo.',
      pending: { upload: 'Carregamento Pendente', verification: 'Verificação Pendente' },
      poidTypes: {
        photoId: 'Identificação com Foto',
        passport: 'Passaporte',
        drivingLicence: 'Carta de Condução',
      },
      porTypes:
        '<li>Fatura de Serviços</li>\n      <li>Extrato Bancário</li>\n      <li>Certificado de Residência</li>',
    },
    finishPage: {
      title: '{individualUserName}, obrigado por se inscrever numa conta real {platformName}!',
      contentDefault:
        'Recebemos a sua inscrição e configuramos a sua conta real <b>{account}</b>. <br />\n      Em breve, receberá um e-mail em <b>{email}</b> contendo os seus detalhes de início de sessão e um link para visualizar o estado da sua conta. <br />',
      contentWithoutId3WithAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br /><br />\n      Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.<br /><br />',
      contentWithoutId3WithoutAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Verificação de Abertura de Conta',
      personalInformation: {
        narrative: 'A sua informação pessoal é necessária para abrir a sua Conta Real',
        firstName: 'Nome Próprio',
        middleName: 'Nome do Meio',
        lastName: 'Apelido',
        gender: 'Género',
        genderList: { male: 'Masculino', female: 'Feminino', other: 'Outro' },
        dob: 'Data de Nascimento',
        day: 'Dia',
        month: 'Mês',
        year: 'Ano',
        mobile: 'Número de Telemóvel',
        countryName: 'País de Residência',
        nationality: 'Nacionalidade',
      },
      createLiveAccount: {
        narrative:
          'Conclua alguns passos para abrir uma conta e começar a financiar. Mais informações sobre os nossos tipos de conta <a href="{link}" target="_blank" alt>aqui</a>.',
        choose: 'Escolha {dynamic}',
        tradingPlatform: 'a Plataforma de Negociação',
        accountType: 'o Tipo de Conta',
        accountCurrency: 'a Moeda da Conta',
        islamicTooltip:
          'A {platform} pode solicitar-lhe que apresente um ou mais documentos comprovativos da sua fé islâmica. A {platform} reserva-se o direito de alterar a configuração da sua conta se a verificação falhar.',
        successInfo: {
          title: 'Conta de Negociação Criada',
          content:
            'Parabéns! A abertura da conta foi concluída com sucesso. <br /> Para começar a negociar, realize um depósito agora e conclua a verificação da sua identidade.',
        },
        successInfo2: {
          title: 'Conta de Negociação Criada',
          content:
            'Preencha as informações do seu perfil financeiro para iniciar o passo seguinte na verificação da sua conta',
          start: 'Começar',
        },
        generalInfo: {
          title: 'A Sua Candidatura Está a Ser Analisada.',
          content:
            'Obrigado por abrir uma conta na {platform}. Pode começar a financiar a sua conta assim que a sua candidatura for aprovada.',
        },
        rejectedInfo: {
          title: 'Rejeitado',
          content:
            'Lamentamos informar que o seu pedido de abertura de uma conta {platform} foi rejeitado.',
        },
      },
    },
    identityVerification: {
      title: 'Verificação de Identidade',
      subTitle: 'Informações de Identidade',
      narrative: 'Preencha os detalhes',
      firstName: 'Nome Próprio',
      middleName: 'Nome do Meio',
      lastName: 'Apelido',
      nationality: 'Nacionalidade',
      idType: 'Tipo de Identificação',
      idSerialNumber: 'Número de Série da Identificação',
      passportNum: 'Número do Passaporte',
      driverLicenseNum: 'Número da Carta de Condução',
      upload: {
        title: 'Carregar Documento',
        desc: 'Certifique-se de que o documento apresenta a sua fotografia, nome completo, data de nascimento e data de emissão.',
        guideline: 'Orientações de Carregamento',
        positive: 'Carregamento Positivo',
        signaturePage: 'Carregar a Página de Assinatura',
      },
      idTypeDropdown: {
        passport: 'Passaporte',
        idCard: 'Cartão Nacional de Identidade',
        driverLicense: 'Carta de Condução',
        other: 'Outro',
      },
      additionalInfo: {
        title: 'Requeridas Informações Adicionais',
        content: 'Verifique a sua identidade para começar a negociar',
      },
      successInfo: {
        title: 'Verificação de Identidade Concluída',
        content:
          'Parabéns! A sua Identificação foi verificada. Pode agora começar a negociar na MT4/MT5 ou na app {platform} se já tiver realizado um depósito.',
      },
      generalInfo: {
        title: 'A sua Candidatura está Sob Revisão.',
        content:
          'Obrigado por abrir uma conta na {platform}. Pode começar a negociar assim que as suas informações de identidade forem verificadas.',
      },
      rejectedInfo: {
        title: 'Rejeitado',
        content:
          'Lamentamos informar que o seu pedido de abertura de uma conta {platform} foi rejeitado.',
      },
      identifyingRequiredInfo: {
        title: 'Requeridas Informações Adicionais',
        content:
          'Alguns dos seus documentos de identificação podem estar em falta ou incorretos. Por favor, volte a carregá-los para prosseguir.',
      },
      streetNumber: 'Número na Rua',
      streetName: 'Nome de Rua',
    },
    poaVerification: {
      proofOfAddressVer: 'Verificação do Comprovativo de Morada',
      subTitle: 'Informação de Morada',
      narrative: 'Preencha os detalhes',
      dueToAML:
        'Devido aos requisitos AML, a sua morada tem de ser verificada antes de poder realizar um levantamento.',
      nationality: 'Nacionalidade',
      country: 'País de Residência',
      address: 'Morada',
      postalCode: 'Código Postal',
      citySuburb: 'Cidade/Subúrbio',
      city: 'A cidade',
      provinceState: 'Distrito/Região',
      upload: {
        title: 'Certificado POA',
        inst1: 'Conta de serviços (eletricidade, água, gás)',
        inst2: 'Extratos bancários ou de cartão de crédito',
        desc: 'As informações de POA para levantamentos devem ser emitidas nos últimos {months} meses',
        guideline: 'Orientações de Carregamento',
        uploadFilesPhoto: 'Carregar Ficheiros/Foto',
      },
      additionalInfo: {
        title: 'Requeridas Informações Adicionais',
        content: 'O POA tem de ser verificado antes de se poder realizar um levantamento.',
      },
      successInfo: {
        title: 'Comprovativo de Morada Verificado',
        content: 'Parabéns! O seu POA foi verificado. Já pode realizar levantamentos.',
      },
      generalInfo: {
        title: 'A sua Candidatura está Sob Revisão.',
        content:
          'Obrigado por negociar com {platform}. Você pode iniciar o processo de retirada assim que seu envio for aprovado.',
      },
      identifyingRequiredInfo: {
        title: 'Requeridas Informações Adicionais',
        content:
          'Alguns dos seus documentos de identificação podem estar em falta ou incorretos. Por favor, volte a carregá-los para prosseguir.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'As suas Informações Financeiras',
        subTitle: 'Informações sobre Emprego e Finanças',
        subTitle2: 'Conhecimentos e Experiência em Negociação',
        employmentStatus: 'Situação Laboral',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} por Semana',
        estimatedAnnualIncome: 'Rendimento anual estimado',
        estimatedSavingsAndInvestments: 'Estimativa de Poupanças e Investimentos',
        estimatedIntendedDeposit: 'Depósito Pretendido Estimado',
        sourceOfFunds: 'Origem dos Fundos',
        numberOfTrades: 'Número de Negociações',
        tradingAmount: 'Montante de Negociação',
      },
      iDProof: {
        ver: 'Carregue o Comprovativo de Identidade',
        subTitle: 'Informações de Identidade',
        narrative: 'Preencha os detalhes',
      },
      addressProof: {
        ver: 'Carregue o Comprovativo de Morada (POA)',
        subTitle: 'Informação de Morada',
        narrative: 'Preencha os detalhes',
        upload: {
          title: 'Documentos aceites como Comprovativo de Morada',
          inst1: 'Conta de serviços (eletricidade, água, gás)',
          inst2: 'Extratos bancários ou de cartão de crédito',
          desc: 'As informações de POA para levantamentos devem ser emitidas nos últimos {months} meses',
          guideline: 'Orientações de Carregamento',
          uploadFilesPhoto: 'Carregar Ficheiros/Foto',
        },
      },
      successInfo: {
        title: 'Autenticação Bancária Concluída',
        content: 'Parabéns! Verificou com sucesso os seus dados bancários.',
      },
      generalInfo: {
        title: 'A sua Candidatura está Sob Revisão.',
        content:
          'Seus documentos para transferência bancária ainda estão em análise. Caso você deseje começar a negociar agora, você ainda pode fazer um depósito por meio de outro canal.',
      },
      rejectedInfo: {
        title: 'Rejeitado',
        content: 'O seu pedido para realizar um depósito por transferência bancária foi rejeitado.',
      },
      identifyingRequiredInfo: {
        title: 'Requeridas Informações Adicionais',
        content:
          'Alguns dos seus documentos de identificação podem estar em falta ou incorretos. Por favor, volte a carregá-los para prosseguir.',
      },
    },
  },
  ibProfile: {
    title: 'PERFIL IB',
    subTitle: 'IB Compartilhado',
    sharedIbList: {
      sharedIbAccount: 'Conta IB compartilhada',
      maxSharedIbAccount: 'Máximo de 10 contas IB compartilhadas',
      add: 'Adicionar',
      accountHistory: 'Histórico da Conta IB Compartilhada',
      setting: 'Configuração',
      delete: 'Excluir',
      enable: 'Ativar',
      disable: 'Desativar',
      enabled: 'Ativado',
      disabled: 'Desativado',
      sharedAccountEnabled: 'A conta IB compartilhada foi ativada.',
      sharedAccountDisabled: 'A conta IB compartilhada foi desativada.',
      sharedAccountCopied: 'Conta IB compartilhada copiada',
      deleteTips:
        'A conta IB compartilhada não poderá fazer login após a exclusão, você deseja excluí-la?',
      sharedAccountDeleted: 'A conta IB compartilhada foi excluída.',
      copyTips: 'Conta IB compartilhada copiada',
      remark: 'Observação',
      cancel: 'Cancelar',
      accountData: 'Dados da conta',
      IBData: 'Dados do relatório IB',
      fundOperations: 'Permissão de fundos',
      resetPassword: 'Redefinir senha',
      accountHistoryBtn: 'Histórico da conta compartilhada',
    },
    updateSharedIb: {
      addNewSharedIb: 'Adicionar nova conta IB compartilhada',
      editSharedIb: 'Editar conta IB compartilhada',
      maxSharedIbAccountError: 'Existem 10 contas, não é possível adicionar mais.',
      email: 'Endereço de e-mail',
      emailValidate: 'Por favor, insira um endereço de e-mail válido',
      emailError: 'O e-mail já existe, por favor, tente outro',
      password: 'Senha',
      passwordValidate: 'Por favor, insira a senha',
      passwordValidateNum: '8 a 16 caracteres',
      passwordValidateTips:
        'Mistura de letras maiúsculas e minúsculas (A-Z, a-z), números (0-9) e caracteres especiais (por exemplo: !@#S%^&)',
      passwordValidateWarning:
        '8 a 16 caracteres,Mistura de letras maiúsculas e minúsculas (A-Z, a-z), números (0-9) e caracteres especiais (por exemplo: !@#S%^&)',
      sharedIbAccountPermission: 'Permissão da Conta IB Compartilhada',
      remark: 'Observação',
      remarkTips: 'Você pode registrar o propósito da conta ou o titular da conta',
      status: 'Status',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      disabled: 'Desativado',
      enabled: 'Ativado',
      resetPassword: 'Redefinir senha',
      emailPlaceholder: 'Por favor, insira seu e-mail',
      addSuccess: 'Conta compartilhada criada',
      permissionIdsTips:
        'Você pode permitir ou não as seguintes permissões para contas compartilhadas:',
      permissionIdsFirst:
        '<b>Dados da conta</b>: Visualizar páginas de dados da conta (Contas IB, Contas abertas recentemente). Os dados serão ocultados se a permissão não for permitida.',
      permissionIdsSecond:
        '<b>Dados do relatório IB</b>: Visualizar páginas de dados do IB (Painel, IB multinível - conta). Os dados serão ocultados se a permissão não for permitida.',
      permissionIdsThird:
        '<b>Permissão de fundos</b>: Operar com fundos IB (Solicitar rebate, Transferir rebate, Retirar rebate), Ver reembolso (relatório de descontos, registros de saldo e fundos). As operações não podem ser realizadas se a permissão não for permitida.',
      newPassword: 'Digite a nova senha',
      newPassowrdReset: 'Digite a nova senha',
      passwordSame: 'A nova senha não pode ser a mesma que a antiga.',
      resetPasswordSuccess: 'Redefinição de senha bem-sucedida',
      enterNewPassword: 'Digite a nova senha',
      changePasswordTips: 'A senha antiga será invalidada e redefinida com a nova senha',
      fundsChangeTips:
        'Após a permissão de depósito ser concedida, a conta IB compartilhada pode transferir e retirar o saldo de sua conta IB principal. Você deseja permitir isso?',
      editSuccess: 'Modificado com sucesso',
    },
    sharedIbHistory: {
      allHistory: 'Todo o histórico foi mostrado',
      lastUpdate: 'Última atualização',
      refreshTips: 'Histórico atualizado',
      refresh: 'Atualizar',
      chooseAccount: 'Escolher Conta',
      device: 'Dispositivo',
      login: 'Login',
      commission: 'Aplicar rebate:',
      withdraw: 'Retirar rebate',
      transfer: 'Transferir rebate',
      fromTo: 'De {fromAccount} para {toAccount}, valor {total}',
      viewMore: 'Ver mais',
    },
    npPermissionTips: 'Sem permissão para esta conta',
  },
  agreement: {
    title: 'Introdução ao Acordo de Corretagem Confiming',
    rebateAccount: 'Conta de desconto',
    description:
      'Ao clicar em Concordo, confirma que leu, compreendeu e concorda com todas as informações, termos de serviço e condições do acordo',
    agree: 'Concordar',
    signed: 'Conta de desconto {accountID}. Assinado em {signedTime}',
    IBAgreement: 'Acordo IB',
    confirmAgreement: 'Contacte o gestor de IB para confirmar o acordo de IB',
  },
};

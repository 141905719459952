// 進行同步執行

export default {
  setAID(state, value) {
    state.AID = value
  },
  setCUR(state, value) {
    state.CUR = value
  },
  setBalance(state, value) {
    state.balance = value
  },
  setDataSourceId(state, value) {
    state.dataSourceId = value
  },
  setShortcut(state, value) {
    state.shortcut = value
  },
  setStartDate(state, value) {
    state.startDate = value
  },
  setEndDate(state, value) {
    state.endDate = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = ''
    })
  },
}

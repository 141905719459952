import { CompactEncrypt, compactDecrypt, importJWK } from 'jose';

const publicKeyJwk = {
  // RSA 公钥 JWK 格式
  "kty": "RSA",
  "kid": "0DIuMtn-VScm9OUZlGzHcmYgcz97kZkyBTlx4U6_OE0",
  "use": "enc",
  "alg": "RSA-OAEP-256",
  "e": "AQAB",
  "n": "u7P_v2f7q6veULiqNgwlHW4Umeof7c1iw82xEa2J35vumBGwYwAHU2pPRHWGyIHO06ptoJY-w0JNt8KcHCYcoZ7L-BssORNF3DGJ-KtkHwxRIfFcSBedyKUtdhCQeCRCe7I6IrAI1GQItN8Y-8sCZ5nIsnNUb5k_AyVpTJehnZ55kVTa5L8GVKyh5NqTAPWCx7vAmVEmq1R6R8syx7HmfrCBMM48bG0GRoBlraEjKe5cwlJI53TvWKkCPQVkK6O-X4MApAFpbmqVNYAvkBxLIT4Hh75oZ2H9zaFTVd4JBmtqdKdUEODVABmP_UjuBmLa4nnf4uzgSMSpls8IzfrmnQ"
};

const privateKeyJwk = {
  "kty": "RSA",
  "kid": "0DIuMtn-VScm9OUZlGzHcmYgcz97kZkyBTlx4U6_OE0",
  "use": "enc",
  "alg": "RSA-OAEP-256",
  "e": "AQAB",
  "n": "u7P_v2f7q6veULiqNgwlHW4Umeof7c1iw82xEa2J35vumBGwYwAHU2pPRHWGyIHO06ptoJY-w0JNt8KcHCYcoZ7L-BssORNF3DGJ-KtkHwxRIfFcSBedyKUtdhCQeCRCe7I6IrAI1GQItN8Y-8sCZ5nIsnNUb5k_AyVpTJehnZ55kVTa5L8GVKyh5NqTAPWCx7vAmVEmq1R6R8syx7HmfrCBMM48bG0GRoBlraEjKe5cwlJI53TvWKkCPQVkK6O-X4MApAFpbmqVNYAvkBxLIT4Hh75oZ2H9zaFTVd4JBmtqdKdUEODVABmP_UjuBmLa4nnf4uzgSMSpls8IzfrmnQ",
  "d": "Bj2m3DNV39BYFPD-38splL_rOW7aXuq8ROxa3c6aTe_-T9oOtkvy8mgPeKQ7tgDy4oDBIly8e3uB4AE9dYYi35DLEVW986vbX8XZ44W3T3z2WZ2PxunaGii7yTglxYjXGQvE-RBHSuCElZbxWrXWP6GSVbSV8xvyR2WACcbk3buvh8aCht1ji5mll20Y0tzwoauLcaLQPlduwkbfyaBV-qwHgrFCphP7eIRYhrZTS0qDzh0AP9V-GWixASmW0cyjOElEBbBwRyqvKXG47AXW9_PkhTbu0UTwT4pY6qUfr2vDGDhoGynNm_G1v5GYqlZPxh89lX_FU4wdbIVZi7kUVQ",
  "p": "7sEam8mynddTVbGC67z5RS4EoDPRnk5L5zsKosymrFnLI3Bef03Tr8m3oboN64FTGDc22sSGYbg3NAnMTjjKYcCOrmc1HNe7YwXlqcONOsDQ4DX74nroyFLMAY1mi0TM5L5w3vuxrHXEivZe_eL74AJcdyCnKo7Z5On2nHcco1c",
  "q": "yULjY7eMazFuThJdnaLw52mGl4FP2Q61gCs5VFtd-6QhnVgE55v_uGlNotBY05s8VBhY1RKhudAM9GZgcgLkDPmYh3ap_1RtXaPtbJHYJAVkSWCgkPGN0XGXlgr1ZX2K9Y9EKi2DqCiqHW5gsB3iF1-VoYjl3M-3qma7v77E4Ss",
  "dp": "wc2CGd9Z3AJDpRXYnh7hgKRgIOKvTv9jYaltcsUIrnKdEIFQU6oLIXBuXRs33Wk22DtJjb9lDcGJHfauHvi-Xyb9KtigJm9_rlvFF06jP4PwiW-IrUjz2FhNPR1YlcgdQbjMylQo30MhbWObrB8qMaX5BMI-r68RdF3uTwfo-TU",
  "dq": "BxAt4sYCS5DEikDiwDGDuttdOdvz7W4yuHjL3kYGmlo8Q8VfBrHW26X2b4xYUbTU_S6Nj4zD2D2Q9tLQ969aSYpI7BgiBy_zVqH2JyVHLJxz1I9rkyqnC36tDzr7OcCaK9wHTGOkhzZGIhpJkmS6Fo_oNG8VghZYH8bTN_t99Vk",
  "qi": "yJHGQdXEJPgKOdRAqVyeGDzFmRW0rOLv4OdjpkUW1aPxQVFKXxJF_CrZ1u6s8sM1nRrfRfvDLffPZSKSEjbRdKw9dOJsAxLJjZtQ-ma8TAOirgU8yn46Qg0VXqiGkM3awV1PdYaIxxPg-pCQhh_uthglSbLTrEfgfYgKzx-6G9Q"
};

const JWEDecrypt = async (ciphertext) => {
  const privateKey = await importJWK(privateKeyJwk, 'RSA-OAEP-256');

  const { plaintext } = await compactDecrypt(
    ciphertext,
    privateKey
  );

  const payload = JSON.parse(new TextDecoder().decode(plaintext));
  return payload;
}


const JWEEncrypt = async (payload, expirationMinutes) => {
  const publicKey = await importJWK(publicKeyJwk, 'RSA-OAEP-256');

  // 创建包含过期时间的 payload
  const expirationTime = Math.floor(Date.now() / 1000) + (expirationMinutes * 60); // 以秒为单位
  const payloadStr = JSON.stringify({
      ...payload,
      exp: expirationTime
  });

  const jwe = await new CompactEncrypt(
      new TextEncoder().encode(payloadStr)
  )
  .setProtectedHeader({ alg: 'RSA-OAEP-256', enc: 'A256GCM' })
  .encrypt(publicKey);

  return jwe;
}

export { JWEDecrypt, JWEEncrypt }

import Vue from 'vue'

Vue.mixin({
  computed: {
    regulator() {
      return this.$store.state.common.regulator
    },
    GLOBAL_DOMAIN_WEBSITE() {
      return this.$store.state.regulator.domainWebSite || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITECN() {
      return this.$store.state.regulator.domainWebSiteCn || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_CONTACT_EMAIL() {
      return this.$store.state.regulator.contactEmail || this.$config.info.getEmail(this.regulator)
    },
    GLOBAL_ACCOUNT_EMAIL() {
      return this.$store.state.regulator.accountEmail || this.$config.info.getAccountEmail(this.regulator)
    },
    GLOBAL_REFERRAL_SITE() {
      return this.$store.state.regulator.referralSite || this.$config.info.getReferralSite(this.regulator)
    },
    GLOBAL_REFERRAL_REBATE_SITE() {
      return this.$store.state.regulator.referralRebateSite || this.$config.info.referralRebateSite(this.regulator)
    },
    GLOBAL_BLACKLIST_EMAIL() {
      return this.$config.info.getBlacklistEmail(this.regulator)
    },
  },
  methods: {
    showLiveChat() {
      zE('messenger', 'open')
    },
  },
})

import { countryCodeEnum } from './country'

export const WHITELIST = {
  [countryCodeEnum.ALGERIA]: true,
  [countryCodeEnum.ARGENTINA]: true,
  [countryCodeEnum.ARMENIA]: true,
  [countryCodeEnum.AUSTRALIA]: true,
  [countryCodeEnum.BAHAMAS]: true,
  [countryCodeEnum.BANGLADESH]: true,
  [countryCodeEnum.BARBADOS]: true,
  [countryCodeEnum.BOLIVIA]: true,
  [countryCodeEnum.BRAZIL]: true,
  [countryCodeEnum.BRUNEI_DARUSSALAM]: true,
  [countryCodeEnum.CAPE_VERDE]: true,
  [countryCodeEnum.CAMBODIA]: true,
  [countryCodeEnum.CANADA]: true,
  [countryCodeEnum.CAYMAN_ISLANDS]: true,
  [countryCodeEnum.CHILE]: true,
  [countryCodeEnum.CHINA]: true,
  [countryCodeEnum.COLOMBIA]: true,
  [countryCodeEnum.COSTA_RICA]: true,
  [countryCodeEnum.DOMINICA]: true,
  [countryCodeEnum.DOMINICAN_REPUBLIC]: true,
  [countryCodeEnum.ECUADOR]: true,
  [countryCodeEnum.EL_SALVADOR]: true,
  [countryCodeEnum.GUATEMALA]: true,
  [countryCodeEnum.HAITI]: true,
  [countryCodeEnum.HONDURAS]: true,
  [countryCodeEnum.HONG_KONG]: true,
  [countryCodeEnum.INDIA]: true,
  [countryCodeEnum.INDONESIA]: true,
  [countryCodeEnum.JORDAN]: true,
  [countryCodeEnum.LAOS]: true,
  [countryCodeEnum.LIBERIA]: true,
  [countryCodeEnum.MADAGASCAR]: true,
  [countryCodeEnum.MALAYSIA]: true,
  [countryCodeEnum.MARSHALL_ISLANDS]: true,
  [countryCodeEnum.MEXICO]: true,
  [countryCodeEnum.MONGOLIA]: true,
  [countryCodeEnum.MOROCCO]: true,
  [countryCodeEnum.NEW_CALEDONIA]: true,
  [countryCodeEnum.NICARAGUA]: true,
  [countryCodeEnum.OMAN]: true,
  [countryCodeEnum.PAKISTAN]: true,
  [countryCodeEnum.PALAU]: true,
  [countryCodeEnum.PANAMA]: true,
  [countryCodeEnum.PAPUA_NEW_GUINEA]: true,
  [countryCodeEnum.PARAGUAY]: true,
  [countryCodeEnum.PERU]: true,
  [countryCodeEnum.PHILIPPINES]: true,
  [countryCodeEnum.RWANDA]: true,
  [countryCodeEnum.SAINT_LUCIA]: true,
  [countryCodeEnum.SAMOA]: true,
  [countryCodeEnum.SEYCHELLES]: true,
  [countryCodeEnum.SINGAPORE]: true,
  [countryCodeEnum.SOUTH_KOREA]: false,
  [countryCodeEnum.SRI_LANKA]: true,
  [countryCodeEnum.SAINT_VINCENT_AND_THE_GRENADINES]: true,
  [countryCodeEnum.SURINAME]: true,
  [countryCodeEnum.TAIWAN]: true,
  [countryCodeEnum.TANZANIA]: true,
  [countryCodeEnum.THAILAND]: true,
  [countryCodeEnum.TRINIDAD_AND_TOBAGO]: true,
  [countryCodeEnum.URUGUAY]: true,
  [countryCodeEnum.VANUATU]: true,
  [countryCodeEnum.VIETNAM]: true,
  [countryCodeEnum.ZAMBIA]: true,
  [countryCodeEnum.ANTARCTICA]: true,
  [countryCodeEnum.BELIZE]: true,
  [countryCodeEnum.JAMAICA]: true,
  [countryCodeEnum.MACAO]: true,
  [countryCodeEnum.FRENCH_GUIANA]: true,
  [countryCodeEnum.MOZAMBIQUE]: true,
  [countryCodeEnum.FRENCH_POLYNESIA]: true,
  [countryCodeEnum.MALAWI]: true,
  [countryCodeEnum.FIJI]: true,
  [countryCodeEnum.GUYANA]: true,
  [countryCodeEnum.TONGA]: true,
  [countryCodeEnum.TURKS_AND_CAICOS_ISLANDS]: true,
  [countryCodeEnum.ETHIOPIA]: true,
  [countryCodeEnum.JAPAN]: true,
  [countryCodeEnum.MAURITANIA]: true,
  [countryCodeEnum.NEPAL]: true,
  [countryCodeEnum.NEW_ZEALAND]: true,
  [countryCodeEnum.SOLOMON_ISLANDS]: true,
  [countryCodeEnum.AFGHANISTAN]: true,
  [countryCodeEnum.EGYPT]: true,
  [countryCodeEnum.BELARUS]: true,
  [countryCodeEnum.NORTH_MARIANA]: true,
  [countryCodeEnum.PUERTO_RICO]: true,
  [countryCodeEnum.BHUTAN]: true,
  [countryCodeEnum.BURUNDI]: true,
  [countryCodeEnum.TIMOR_LESTE]: true,
  [countryCodeEnum.RUSSIA]: true,
  [countryCodeEnum.ERITREA]: true,
  [countryCodeEnum.CUBA]: true,
  [countryCodeEnum.GUAM]: true,
  [countryCodeEnum.KIRIBATI]: true,
  [countryCodeEnum.GUINEA]: true,
  [countryCodeEnum.GUINEA_BISSAU]: true,
  [countryCodeEnum.COOK_ISLANDS]: true,
  [countryCodeEnum.LEBANON]: true,
  [countryCodeEnum.LIBYA]: true,
  [countryCodeEnum.MALDIVES]: true,
  [countryCodeEnum.MALI]: true,
  [countryCodeEnum.UNITED_STATES]: true,
  [countryCodeEnum.MICRONESIA]: true,
  [countryCodeEnum.MYANMAR]: true,
  [countryCodeEnum.NAURU]: true,
  [countryCodeEnum.SUDAN]: true,
  [countryCodeEnum.SOMALIA]: true,
  [countryCodeEnum.TAJIKISTAN]: true,
  [countryCodeEnum.TUNISIA]: true,
  [countryCodeEnum.TUVALU]: true,
  [countryCodeEnum.VENEZUELA]: true,
  [countryCodeEnum.UKRAINE]: true,
  [countryCodeEnum.SYRIA]: true,
  [countryCodeEnum.IRAQ]: true,
  [countryCodeEnum.IRAN]: true,
  [countryCodeEnum.CENTRAL_AFRICAN]: true,
  [countryCodeEnum.AMERICAN_SAMOA]: true,
  [countryCodeEnum.CONGO_DEMOCRATIC]: true,
  [countryCodeEnum.NORTH_KOREA]: true,
  [countryCodeEnum.PALESTINIAN]: true,
  [countryCodeEnum.ST_KITTS_NEVIS]: true,
  [countryCodeEnum.SINT_MAARTEN]: true,
  [countryCodeEnum.KOREA]: true,
  [countryCodeEnum.SOUTH_SUDAN]: true,
  [countryCodeEnum.ARUBA]: true,
  [countryCodeEnum.BRITISH_INDIAN_OCEAN]: true,
  [countryCodeEnum.CHRISTMAS_ISLAND]: true,
  [countryCodeEnum.COCOS_ISLANDS]: true,
  [countryCodeEnum.FALKLAND_ISLANDS]: true,
  [countryCodeEnum.FRENCH_SOUTHERN_TERR]: true,
  [countryCodeEnum.HEARD_MCDONALD_ISLANDS]: true,
  [countryCodeEnum.NETHERLANDS_ANTILLES]: true,
  [countryCodeEnum.NIUE]: true,
  [countryCodeEnum.NORFOLK_ISLAND]: true,
  [countryCodeEnum.PITCAIRN]: true,
  [countryCodeEnum.ST_PIERRE_MIQUELON]: true,
  [countryCodeEnum.TOKELAU]: true,
  [countryCodeEnum.VIRGIN_ISLANDS]: true,
  [countryCodeEnum.WESTERN_SAHARA]: true,
  [countryCodeEnum.ANTIGUA_AND_BARBUDA]: true,
  [countryCodeEnum.AUSTRIA]: true,
  [countryCodeEnum.BELGIUM]: true,
  [countryCodeEnum.CAMEROON]: true,
  [countryCodeEnum.CROATIA]: true,
  [countryCodeEnum.CYPRUS]: true,
  [countryCodeEnum.DENMARK]: true,
  [countryCodeEnum.ESTONIA]: true,
  [countryCodeEnum.FINLAND]: true,
  [countryCodeEnum.FRANCE]: true,
  [countryCodeEnum.GEORGIA]: true,
  [countryCodeEnum.GERMANY]: true,
  [countryCodeEnum.GHANA]: true,
  [countryCodeEnum.GREECE]: true,
  [countryCodeEnum.ICELAND]: true,
  [countryCodeEnum.IRELAND]: true,
  [countryCodeEnum.ITALY]: true,
  [countryCodeEnum.KAZAKHSTAN]: true,
  [countryCodeEnum.KOSOVO]: true,
  [countryCodeEnum.LUXEMBOURG]: true,
  [countryCodeEnum.MACEDONIA]: true,
  [countryCodeEnum.MALTA]: true,
  [countryCodeEnum.NAMIBIA]: true,
  [countryCodeEnum.NETHERLANDS]: true,
  [countryCodeEnum.NIGERIA]: true,
  [countryCodeEnum.NORWAY]: true,
  [countryCodeEnum.POLAND]: true,
  [countryCodeEnum.PORTUGAL]: true,
  [countryCodeEnum.SAINT_MARTIN]: true,
  [countryCodeEnum.SLOVAKIA]: true,
  [countryCodeEnum.SLOVENIA]: true,
  [countryCodeEnum.SPAIN]: true,
  [countryCodeEnum.TURKEY]: true,
  [countryCodeEnum.SWAZILAND]: true,
  [countryCodeEnum.SWEDEN]: true,
  [countryCodeEnum.SWITZERLAND]: true,
  [countryCodeEnum.UNITED_ARAB_EMIRATES]: true,
  [countryCodeEnum.UNITED_KINGDOM]: true,
  [countryCodeEnum.ANDORRA]: true,
  [countryCodeEnum.ANGOLA]: true,
  [countryCodeEnum.BAHRAIN]: true,
  [countryCodeEnum.BULGARIA]: true,
  [countryCodeEnum.BOTSWANA]: true,
  [countryCodeEnum.CZECH_REPUBLIC]: true,
  [countryCodeEnum.KUWAIT]: true,
  [countryCodeEnum.KENYA]: true,
  [countryCodeEnum.LATVIA]: true,
  [countryCodeEnum.LITHUANIA]: true,
  [countryCodeEnum.MOLDOVA]: true,
  [countryCodeEnum.HUNGARY]: true,
  [countryCodeEnum.ISRAEL]: true,
  [countryCodeEnum.QATAR]: true,
  [countryCodeEnum.ROMANIA]: true,
  [countryCodeEnum.SOUTH_AFRICA]: true,
  [countryCodeEnum.SERBIA]: true,
  [countryCodeEnum.SAUDI_ARABIA]: true,
  [countryCodeEnum.UGANDA]: true,
  [countryCodeEnum.GABON]: true,
  [countryCodeEnum.BENIN]: true,
  [countryCodeEnum.LESOTHO]: true,
  [countryCodeEnum.SENEGAL]: true,
  [countryCodeEnum.ALAND_ISLANDS]: true,
  [countryCodeEnum.SAINT_BARTHELEMY]: true,
  [countryCodeEnum.AZERBAIJAN]: true,
  [countryCodeEnum.TOGO]: true,
  [countryCodeEnum.MAURITIUS]: true,
  [countryCodeEnum.GUADELOUPE]: true,
  [countryCodeEnum.MARTINIQUE]: true,
  [countryCodeEnum.REUNION]: true,
  [countryCodeEnum.BURKINA_FASO]: true,
  [countryCodeEnum.GRENADA]: true,
  [countryCodeEnum.GREENLAND]: true,
  [countryCodeEnum.MONACO]: true,
  [countryCodeEnum.ANGUILLA]: true,
  [countryCodeEnum.GIBRALTAR]: true,
  [countryCodeEnum.JERSEY]: true,
  [countryCodeEnum.UZBEKISTAN]: true,
  [countryCodeEnum.GUERNSEY]: true,
  [countryCodeEnum.ISLE_OF_MAN]: true,
  [countryCodeEnum.MAYOTTE]: true,
  [countryCodeEnum.ALBANIA]: true,
  [countryCodeEnum.BOSNIA_AND_HERZEGOVINA]: true,
  [countryCodeEnum.KYRGYZSTAN]: true,
  [countryCodeEnum.CHAD]: true,
  [countryCodeEnum.CONGO_REPUBLIC]: true,
  [countryCodeEnum.VATICAN_CITY_STATE]: true,
  [countryCodeEnum.COTE_D_IVOIRE]: true,
  [countryCodeEnum.BERMUDA]: true,
  [countryCodeEnum.FAROE_ISLAND]: true,
  [countryCodeEnum.GAMBIA]: true,
  [countryCodeEnum.DJIBOUTI]: true,
  [countryCodeEnum.COMOROS]: true,
  [countryCodeEnum.LIECHTENSTEIN]: true,
  [countryCodeEnum.NIGER]: true,
  [countryCodeEnum.SIERRA_LEONE]: true,
  [countryCodeEnum.SAO_TOME_PRINCIPE]: true,
  [countryCodeEnum.SAN_MARINO]: true,
  [countryCodeEnum.TURKMENISTAN]: true,
  [countryCodeEnum.EQUATORIAL_GUINEA]: true,
  [countryCodeEnum.MONTENEGRO]: true,
  [countryCodeEnum.BOUVET_ISLAND]: true,
  [countryCodeEnum.MONTSERRAT]: true,
  [countryCodeEnum.ST_HELENA]: true,
  [countryCodeEnum.SOUTH_GEORGIA]: true,
  [countryCodeEnum.SVALBARD]: true,
  [countryCodeEnum.CENTRAL_AFRICAN_REPUBLIC]: true,
  [countryCodeEnum.ZIMBABWE]: true,
  [countryCodeEnum.YEMEN]: true,
}

export const WHITELIST2 = {
  [countryCodeEnum.AFGHANISTAN]: false,
  [countryCodeEnum.BELGIUM]: false,
  [countryCodeEnum.BELARUS]: false,
  [countryCodeEnum.CHINA]: false,
  [countryCodeEnum.CUBA]: false,
  [countryCodeEnum.IRAN]: false,
  [countryCodeEnum.LIBYA]: false,
  [countryCodeEnum.MYANMAR]: false,
  [countryCodeEnum.NORTH_KOREA]: false,
  [countryCodeEnum.SYRIA]: false,
  [countryCodeEnum.SOUTH_SUDAN]: false,
  [countryCodeEnum.SUDAN]: false,
  [countryCodeEnum.UNITED_STATES]: false,
  [countryCodeEnum.VENEZUELA]: false,
}

import Vue from 'vue'
import Router from 'vue-router'
import identity from './identity'
import store from '@/store/index'
import { statusConfig } from '@/constants/status'
import { checkRegisterPageCanAudit, generalRegisterDirectPage, registerFinanicalStep } from '@/util/register'

Vue.use(Router)

async function ibtProofMiddleware(to, from, next) {
  const ibtPoiAuditStatus = store.state.register.ibtPoiAuditStatus
  const ibtPoaAuditStatus = store.state.register.ibtPoaAuditStatus

  const isFirstTimeIBT = ibtPoiAuditStatus === statusConfig.init && ibtPoaAuditStatus === statusConfig.init

  // if user first does IBT verification and had not submitted,
  // refresh 'iDProof' & 'addressProof' page should direct to 'financialInfo'
  if (isFirstTimeIBT && to.name === 'addressProof' && !Object.keys(to.params)?.length) {
    return next({ name: 'financialInfo' })
  }

  switch (to.name) {
    case 'financialInfo':
      return isFirstTimeIBT ? next() : next({ name: '/rebateWithdraw' })
    case 'iDProof':
      return checkRegisterPageCanAudit(ibtPoiAuditStatus) ? next() : next({ name: '/rebateWithdraw' })
    case 'addressProof':
      return checkRegisterPageCanAudit(ibtPoaAuditStatus) ? next() : next({ name: '/rebateWithdraw' })
    default:
      return next()
  }
}

const pages = {
  '/home': () => import('@/views/home/index'),
  '/ibreport': () => import('@/views/IbReport'),
  '/rebatereport': () => import('@/views/RebateReport'),
  '/iblevel': () => import('@/views/IbLevel'),
  '/ibaccounts': () => import('@/views/IbAccounts'),
  '/pendingaccounts': () => import('@/views/PendingAccounts'),
  '/leads': () => import('@/views/Leads'),
  '/rebateTransfer': () => import('@/views/RebateTransfer'),
  '/rebateWithdraw': () => import('@/views/RebateWithdraw'),
  '/profile': () => import('@/views/profile'),
  '/contactUs': () => import(`@/views/ContactUs`),
  '/rebatePaymentHistory': () => import('@/views/RebatePaymentHistory'),
  '/referralLinks': () => import('@/views/ReferralLinks'),
}

let routes = [
  {
    path: '',
    component: () => import('@/views/Layout'),
    children: [
      {
        name: '/',
        path: '/',
        component: () => import('@/views/home/index'),
      },
      ...Object.keys(pages).map(b => {
        return {
          name: b,
          path: b,
          component: pages[b],
        }
      }),
      {
        path: `register/ibtVerification`,
        component: () => import('@/views/register/ibtProof/Index'),
        redirect: () => {
          return {
            name: 'iDProof',
          }
        },
        children: [
          {
            path: `financialInfo`,
            name: 'financialInfo',
            component: () => import('@/views/register/ibtProof/ChildView'),
            meta: {
              restrictBanner: true,
              restrictSideBar: true,
            },
            beforeEnter: ibtProofMiddleware,
          },
          {
            path: `iDProof`,
            name: 'iDProof',
            component: () => import('@/views/register/ibtProof/ChildView'),
            beforeEnter: ibtProofMiddleware,
            meta: {
              restrictBanner: true,
              restrictSideBar: true,
            },
          },
          {
            path: `addressProof`,
            name: 'addressProof',
            component: () => import('@/views/register/ibtProof/ChildView'),
            beforeEnter: ibtProofMiddleware,
            meta: {
              restrictBanner: true,
              restrictSideBar: true,
            },
          },
        ],
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '',
      component: () => import('@/views/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()

export default {
  CUID: 0,
  lang: 'en_US',
  loginStatus: '',
  userName: '',
  email: '',
  phone: '',
  createTime: '',
  address: '',
  cpUrl: '',
  accessToken: '',
  countryCode: '',
  regulator: null,
  autoNotifyStatus: true,
  emailMasked: '',
  id3Pass: false,
  registerStep: 1,
  isSubUser: false,
  subUserSwitch: false,
  permissionIds: [],
  subEmail: '',
  checkedAuthStatus: [],
  requiredAuths: {},
  userId: '',
  eventEmailId: '',
  eventMobileId: '',
  authHeaders: {
    etxId: '',
    eCode: '',
    ptxId: '',
    pCode: '',
    tCode: '',
  },
  sessionId: null,
  sessionInternalId: null,
}

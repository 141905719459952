import Vue from 'vue'
import ResizeObserver from 'resize-observer-polyfill'

let ResizeObsArray = []

Vue.directive('resizeObserver', {
  bind(el, { value }) {
    if (value.openResize)
      ResizeObsArray.push({
        el: el,
        class: new ResizeObsClass(el, value),
      })
  },
  unbind(el) {
    let removeObs = ResizeObsArray.filter((element, index) => {
      if (element.el === el) {
        ResizeObsArray.splice(index, 1)
        return element
      }
    })
    if (removeObs[0]) removeObs[0].class.unobserve(el)
  },
})

export default class ResizeObsClass {
  constructor(el, options) {
    this.handler = options.handler || null
    this.observer = null
    this.init(el)
  }

  init(el) {
    this.observer = new ResizeObserver(entries => {
      this.handler(entries[0].contentRect.width, entries[0].contentRect.height)
    })
    this.observer.observe(el)
  }

  unobserve(el) {
    this.observer.unobserve(el)
  }
}

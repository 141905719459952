// 進行異步執行
import { apiInformation } from '@/resource'

export default {
  async actionGetInformation({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiInformation()
        .then(result => {
          console.log(result)
          if (result.data.domainWebSite) {
            commit('setDomainWebSite', result.data.domainWebSite)
            commit('setDomainWebSiteCn', result.data.domainWebSiteCn)
            commit('setContactEmail', result.data.contactEmail)
            commit('setAccountEmail', result.data.accountEmail)
            commit('setReferralSite', result.data.referralSite)
            commit('setReferralRebateSite', result.data.ibReferalLink)
          } else {
            console.log('error')
          }
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
}

export default {
  methods: {
    addToolkit(sub_domain, orgId, sessionId) {
      // Remove existing toolkit script by ID
      const existingScript = document.getElementById('toolkit-script')
      if (existingScript) {
        document.head.removeChild(existingScript)
      }

      // Remove existing toolkit noscript
      const existingNoscript = document.getElementById('toolkit-noscript')
      if (existingNoscript) {
        document.body.removeChild(existingNoscript)
      }

      // create script
      const toolkitSnippet = document.createElement('script')
      toolkitSnippet.setAttribute('id', 'toolkit-script')
      toolkitSnippet.setAttribute('type', 'text/javascript')
      toolkitSnippet.innerHTML = `toolkit.details("${sub_domain}", "${orgId}", "${sessionId}")`

      // append script to header
      document.head.appendChild(toolkitSnippet)

      // create noscript
      const toolkitBodySnippet = document.createElement('noscript')
      toolkitBodySnippet.setAttribute('id', 'toolkit-noscript')
      const iframe = document.createElement('iframe')
      iframe.width = 0
      iframe.height = 0
      iframe.style = 'display: none; visibility: hidden'
      iframe.src = `https://${sub_domain}/fp/tags?org_id=${orgId}&session_id=${sessionId}`
      toolkitBodySnippet.appendChild(iframe)

      // append script to body
      document.body.appendChild(toolkitBodySnippet)
    },
  },
  watch: {
    '$store.state.common.sessionId': {
      handler(newSessionId) {
        if (newSessionId) {
          const hostname = location.hostname
          const newHostName = hostname
            .split('.')
            .map((part, index) => (index === 0 ? 'afm' : part))
            .join('.')

          this.addToolkit(newHostName, process.env.VUE_APP_ORG_ID, newSessionId)
        }
      },
    },
  },
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementKrLocale from 'element-ui/lib/locale/lang/ko'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementThLocale from 'element-ui/lib/locale/lang/th'
import elementViLocale from 'element-ui/lib/locale/lang/vi'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import elementDeLocale from '@/lang/element-ui/de'
import elementIdLocale from 'element-ui/lib/locale/lang/id'
import elementArLocale from 'element-ui/lib/locale/lang/ar'
import enLocale from '@/lang/pug/en'
import zhLocale from '@/lang/pug/zh-CN'
import frLocale from '@/lang/pug/fr'
import krLocale from '@/lang/pug/kr'
import jaLocale from '@/lang/pug/ja'
import thLocale from '@/lang/pug/th'
import viLocale from '@/lang/pug/vi'
import esLocale from '@/lang/pug/es'
import ptLocale from '@/lang/pug/pt'
import deLocale from '@/lang/pug/de'
import idLocale from '@/lang/pug/id'
import arLocale from '@/lang/pug/ar'

Vue.use(VueI18n)

const messages = {
  en_US: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh_CN: {
    ...zhLocale,
    ...elementZhLocale,
  },
  fr_FR: {
    ...frLocale,
    ...elementFrLocale,
  },
  ko: {
    ...krLocale,
    ...elementKrLocale,
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale,
  },
  th: {
    ...thLocale,
    ...elementThLocale,
  },
  vi: {
    ...viLocale,
    ...elementViLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale,
  },
  de: {
    ...deLocale,
    ...elementDeLocale,
  },
  id: {
    ...idLocale,
    ...elementIdLocale,
  },
  ar: {
    ...arLocale,
    ...elementArLocale,
  },
}

const i18n = new VueI18n({
  messages, // set locale messages
  fallbackLocale: 'en_US',
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n

// 進行異步執行
import { apiID3Status } from '@/resource/register'
import { apiGetSessionId } from '@/resource'

export default {
  async actionUpdateId3Pass({ commit }) {
    return new Promise((resolve, reject) => {
      apiID3Status().then(response => {
        if ((response.data?.data ?? null) !== null) {
          commit('setId3Pass', response.data.data)
          resolve(response.data.data)
        } else {
          reject(false)
        }
      })
    })
  },
  async actionApiGetSessionId({ commit, state }) {
    let res = await apiGetSessionId()
    if (res.data.code === 0 && res.data.data) {
      commit('setSessionId', res.data.data)
    }
  },
}

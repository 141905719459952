function isObject(input) {
    if (typeof input !== 'object') return false
    return true
}

var HtmlUtil = {
    // 1.用浏览器内部转换器实现html编码
    htmlEncode: function (html) {
        // 创建一个元素容器
        var tempDiv = document.createElement('div');
        // 把需要编码的字符串赋值给该元素的innerText(ie支持)或者textContent(火狐、谷歌等) 
        (tempDiv.textContent != undefined) ? (tempDiv.textContent = html) : (tempDiv.innerText = html);
        var output = tempDiv.innerHTML;
        tempDiv = null;
        return output;
    },

    // 2.用浏览器内部转换器实现html解码
    htmlDecode: function (text) {
        // 创建一个元素容器
        var tempDiv = document.createElement('div');
        // 把解码字符串赋值给元素innerHTML
        tempDiv.innerHTML = text;
        // 最后返回这个元素的innerText(ie支持)或者textContent(火狐、谷歌等支持)
        var output = tempDiv.innerText || tempDiv.textContent;
        tempDiv = null;
        return output;
    }
}
export function HTMLdecode(target) {
    if (target == undefined || target == null) return target;
    if (typeof target == 'string') {
        return HtmlUtil.htmlDecode(target)
    }
    if (isObject(target)) {
        Object.keys(target).forEach(function (key) {
            if (Object.hasOwnProperty.call(target, key)) {
                target[key] = HTMLdecode(target[key])
            }
        })
    }
    return target
}